import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog/dialog.component';
import { UploadComponent } from './upload/upload.component';
import { UploadService } from "./upload.service";
import { AppMaterialModule } from './../app-material/app-material.module';


@NgModule({
  declarations: [DialogComponent, UploadComponent],
  imports: [
    CommonModule,
    AppMaterialModule
  ],
  exports: [UploadComponent],
  entryComponents: [DialogComponent], // Add the DialogComponent as entry component
  providers: [UploadService]
})
export class UploadModule { }
