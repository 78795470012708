/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./delete-user-dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./delete-user-dialog.component";
var styles_DeleteUserDialogComponent = [i0.styles];
var RenderType_DeleteUserDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DeleteUserDialogComponent, data: {} });
export { RenderType_DeleteUserDialogComponent as RenderType_DeleteUserDialogComponent };
export function View_DeleteUserDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Er du sikker p\u00E5 at du vil slette denne brukeren?"])), (_l()(), i1.ɵeld(3, 0, null, null, 12, "mat-dialog-content", [["class", "mat-typography mat-dialog-content"]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Brukeren "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " ", ""])), (_l()(), i1.ɵted(-1, null, [" og "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" bilder vil bli slettet. "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" NB! Denne handlingen kan ikke angres. "])), (_l()(), i1.ɵeld(16, 0, null, null, 7, "mat-dialog-actions", [["align", "end"], ["class", "mat-dialog-actions"]], null, null, null, null, null)), i1.ɵdid(17, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(19, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Avbryt"])), (_l()(), i1.ɵeld(21, 0, null, null, 2, "button", [["color", "primary"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirmAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(22, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Slett"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_8 = "primary"; _ck(_v, 22, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.firstname; var currVal_2 = _co.data.lastname; _ck(_v, 8, 0, currVal_1, currVal_2); var currVal_3 = _co.data.foto_count; _ck(_v, 11, 0, currVal_3); var currVal_4 = (i1.ɵnov(_v, 19).disabled || null); var currVal_5 = (i1.ɵnov(_v, 19)._animationMode === "NoopAnimations"); _ck(_v, 18, 0, currVal_4, currVal_5); var currVal_6 = (i1.ɵnov(_v, 22).disabled || null); var currVal_7 = (i1.ɵnov(_v, 22)._animationMode === "NoopAnimations"); _ck(_v, 21, 0, currVal_6, currVal_7); }); }
export function View_DeleteUserDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-delete-user-dialog", [], null, null, null, View_DeleteUserDialogComponent_0, RenderType_DeleteUserDialogComponent)), i1.ɵdid(1, 114688, null, 0, i7.DeleteUserDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DeleteUserDialogComponentNgFactory = i1.ɵccf("app-delete-user-dialog", i7.DeleteUserDialogComponent, View_DeleteUserDialogComponent_Host_0, {}, {}, []);
export { DeleteUserDialogComponentNgFactory as DeleteUserDialogComponentNgFactory };
