import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) {
  }

  private errorHandler(err) {
    if (err.error && err.error.message) {
      alert(err.error.message);
    } else {
      alert('Feil på url: ' + err.url + '\n Status code: ' + err.status + '\n' + err.message);
    }
    return throwError(err);
  }

  getUsers() {
    return this.http.get<any>(environment.BACKEND_SERVER_URL + '/systemuser', { withCredentials: true })
  }

  saveUserData(userId: number, userData) {
    return this.http.post<any>(environment.BACKEND_SERVER_URL + '/systemuser/' + userId, userData, { withCredentials: true })
  }

  createNewUser(userData) {
    return this.http.post<any>(environment.BACKEND_SERVER_URL + '/systemuser', userData, { withCredentials: true })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  deleteUser(userId: number) {
    return this.http.delete<any>(environment.BACKEND_SERVER_URL + '/systemuser/' + userId, { withCredentials: true })
  }

}

