import * as tslib_1 from "tslib";
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { environment } from './../../environments/environment';
var MyImagesComponent = /** @class */ (function () {
    function MyImagesComponent(uploadService, contentService, authService, cdRef, _snackBar, router, activatedRoute) {
        this.uploadService = uploadService;
        this.contentService = contentService;
        this.authService = authService;
        this.cdRef = cdRef;
        this._snackBar = _snackBar;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.pageSizeOptions = [10, 50, 100];
        this.searchParams = {};
        this.files = new Set();
    }
    MyImagesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchParams.offset = '0';
        this.searchParams.limit = '50';
        this.activatedRoute.queryParamMap
            .subscribe(function (queryParams) {
        });
        this.authService.sessionInfo$
            .subscribe(function (sessionInfo) {
            if (sessionInfo) {
                _this.contentService.getMyImages(_this.searchParams)
                    .subscribe(function (images) {
                    _this.images = images.items;
                    _this.noOfImages = images.total;
                });
            }
        });
    };
    MyImagesComponent.prototype.onPageChange = function (event) {
        var _this = this;
        this.searchParams.offset = event.pageIndex;
        this.searchParams.limit = event.pageSize;
        this.router.navigate(['/my-images'], { queryParams: this.searchParams });
        this.authService.sessionInfo$
            .subscribe(function (sessionInfo) {
            if (sessionInfo) {
                _this.contentService.getMyImages(_this.searchParams)
                    .subscribe(function (images) {
                    _this.images = images.items;
                    _this.noOfImages = images.total;
                });
            }
        });
    };
    MyImagesComponent.prototype.ngAfterViewChecked = function () {
        this.cdRef.detectChanges();
    };
    MyImagesComponent.prototype.addFiles = function () {
        this.file.nativeElement.click();
    };
    MyImagesComponent.prototype.onFilesAdded = function () {
        var _this = this;
        var _a;
        var files = this.file.nativeElement.files;
        for (var key in files) {
            if (!isNaN(parseInt(key))) {
                this.files.add(files[key]);
            }
        }
        // start the upload and save the progress map
        var onComplete$;
        _a = tslib_1.__read(this.uploadService.upload(environment.BACKEND_SERVER_URL + '/foto/', this.files), 2), this.progress = _a[0], onComplete$ = _a[1];
        onComplete$.subscribe(function (r) {
            _this.files.delete(r['file']);
            _this.images.unshift(r['response'].foto);
        });
        this.cdRef.detectChanges();
        this.authService.sessionInfo$
            .subscribe(function (sessionInfo) {
            if (sessionInfo) {
                _this.contentService.getMyImages(_this.searchParams)
                    .subscribe(function (images) {
                    _this.images = images.items;
                    _this.noOfImages = images.total;
                });
            }
        });
    };
    MyImagesComponent.prototype.deleteImage = function (image) {
        var _this = this;
        this.contentService.deleteImage(image)
            .subscribe(function (res) {
            _this.images.splice(_this.images.indexOf(image), 1);
            _this.authService.sessionInfo$
                .subscribe(function (sessionInfo) {
                if (sessionInfo) {
                    _this.contentService.getMyImages(_this.searchParams)
                        .subscribe(function (images) {
                        _this.images = images.items;
                        _this.noOfImages = images.total;
                    });
                }
            });
            _this._snackBar.open(image.imageTitle + ' ble slettet', '', { duration: 2000 });
        });
    };
    return MyImagesComponent;
}());
export { MyImagesComponent };
