import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CategoriesService, Category } from '../categories.service';
import { ImageListService } from '../image-list.service';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  @Output() categorySelected = new EventEmitter();
  treeControl = new NestedTreeControl<Category>(node => node.children);
  dataSource = new MatTreeNestedDataSource<Category>();
  editMode: boolean = false;

  ds = new MatTreeNestedDataSource<Category>(); // TEST
  contentForm: FormGroup; // TEST

  constructor(
    private categoriesService: CategoriesService,
    public authService: AuthService,
    private imageListService: ImageListService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private router: Router
  ) {
  }

  hasChild = (_: number, node: Category) => !!node.children && node.children.length > 0;

  ngOnInit() {
    
    this.categoriesService.getCategories()
      .subscribe(categories => {
        this.dataSource.data = categories;


        this.contentForm = this.fb.group({
          categories: this.fb.array(
            categories.map(cat => this.fb.group({
              label: cat.label,
              children: this.fb.array(
                cat.children.map(subcat => this.fb.group({
                  label: subcat.label
                }))
              )
            }))
          )
        });
        this.ds = this.contentForm.value;
      })

      // this.tableForm = this.fb.group({
      //   rows: this.fb.array(
      //     initialTable.rows.map(row => this.fb.group({
      //           cells: this.fb.array(
      //             row.cells.map(cell => this.fb.group(cell))
      //           )
      //         })))
      // });
  }

  get mainCats(): FormArray {
    return (this.contentForm.get('categories') as FormArray);
  }


  searchCategory(id, label) {
    this.categorySelected.next({'category_id': id, 'category_label': label});
    // this.router.navigate(['/search'], {queryParams: {'category_id': id, 'category_label': label, 'offset': 0}});
  }

  editLabel(category) {
    this.categoriesService.updateCategory(category)
      .subscribe(res => {
      })
  }

  addSubCategory(category, treeControl) {
    if (!treeControl.isExpanded(category)) {
      treeControl.expand(category)
    }
    const idx = this.dataSource.data.findIndex(cat => {
      return cat.id === category.id;
    })
    if (this.dataSource.data[idx]) {
      const newCat = {id: null, label: "NY!", parent: category.id};
      this.categoriesService.addCategory(newCat)
      .subscribe(res => {
        newCat.id = res.category.id;
        this.dataSource.data[idx].children.unshift(newCat);
        this.refreshTree();
        this.openSnackBar('La til kategorien ' + newCat.label, '');
      })
    } 
  }

  addMainCategory() {
    const newCat = {id: null, label: "NY!", parent: null, children: []};
      this.categoriesService.addCategory(newCat)
        .subscribe(res => {
          newCat.id = res.category.id;
          this.dataSource.data.unshift(newCat);
          this.refreshTree();
          this.openSnackBar('La til kategorien ' + newCat.label, '');
        });
    this.refreshTree();
  }

  removeCategory(category) {
    if (category.parent === null) {
      if (category.children && category.children.length > 0) {
        this.openSnackBar('Kan ikke slette en kategori som har underkategorier', '');
      } else {
        // Slette tom hovedkategori
        const idx = this.dataSource.data.findIndex(cat => {
          return cat.id === category.id;
        });
        this.dataSource.data.splice(idx, 1);
        this.categoriesService.deleteCategory(category)
          .subscribe(res => {
            this.openSnackBar('Slettet kategorien ' + category.label, '');
          })
      }
    } else {
      // Slette underkategori
      const parentIdx = this.dataSource.data.findIndex(cat => {
        return cat.id === category.parent;
      });
      if (this.dataSource.data[parentIdx]) {
        const idx = this.dataSource.data[parentIdx].children.findIndex(cat => {
          return cat.id === category.id;
        });
        this.dataSource.data[parentIdx].children.splice(idx, 1);
        this.categoriesService.deleteCategory(category)
          .subscribe(res => {
            this.openSnackBar('Slettet kategorien ' + category.label, '');
          })
      }
    }

    this.refreshTree();
  }

  private refreshTree() {
    // Hack
    const data = this.dataSource.data
    this.dataSource.data = null;
    this.dataSource.data = data;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  
  isFirstLevel(_: number, nodeData) {
    return nodeData.parent ? false : true;
  }

}
