/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./image-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular-mdc/web/image-list";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "@angular/flex-layout/core";
import * as i7 from "../../../node_modules/@angular/material/paginator/typings/index.ngfactory";
import * as i8 from "@angular/material/paginator";
import * as i9 from "../../../node_modules/@angular-mdc/web/image-list/typings/index.ngfactory";
import * as i10 from "../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i11 from "@angular/material/progress-spinner";
import * as i12 from "@angular/cdk/platform";
import * as i13 from "@angular/platform-browser/animations";
import * as i14 from "./image-list.component";
import * as i15 from "../image-list.service";
var styles_ImageListComponent = [i0.styles];
var RenderType_ImageListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ImageListComponent, data: {} });
export { RenderType_ImageListComponent as RenderType_ImageListComponent };
function View_ImageListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "mat-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.searchParams.category_label; _ck(_v, 1, 0, currVal_0); }); }
function View_ImageListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "mdc-image-list-item", [["class", "mdc-image-list__item"]], null, null, null, null, null)), i1.ɵdid(1, 16384, null, 0, i2.MdcImageListItem, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵeld(5, 0, null, null, 1, "img", [["class", "mdc-image-list__image"], ["mdcImageListImage", ""]], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(6, 16384, null, 0, i2.MdcImageListImage, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "img", [["class", "hover-image mdc-image-list__image"], ["mdcImageListImage", ""]], [[8, "src", 4]], null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MdcImageListImage, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "mdc-image-list-supporting", [["class", "mdc-image-list__supporting"]], null, null, null, null, null)), i1.ɵdid(10, 16384, null, 0, i2.MdcImageListSupporting, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 2, "span", [["class", "mdc-image-list__label"], ["mdcImageListLabel", ""]], null, null, null, null, null)), i1.ɵdid(12, 16384, null, 0, i2.MdcImageListLabel, [i1.ElementRef], null, null), (_l()(), i1.ɵted(13, null, ["", ""]))], function (_ck, _v) { var currVal_2 = _ck(_v, 4, 0, ("/single-photo/" + _v.context.$implicit.id)); _ck(_v, 3, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.image, ""); _ck(_v, 5, 0, currVal_3); var currVal_4 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.image, ""); _ck(_v, 7, 0, currVal_4); var currVal_5 = _v.context.$implicit.imageTitle; _ck(_v, 13, 0, currVal_5); }); }
function View_ImageListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "ff-image-list-page-container"], ["fxFlex", ""], ["fxLayout", "column"], ["fxLayoutAlign", " center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutStyleBuilder], i6.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i6.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(3, 671744, null, 0, i5.DefaultFlexDirective, [i1.ElementRef, i6.StyleUtils, i6.LAYOUT_CONFIG, i5.FlexStyleBuilder, i6.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "ff-paginator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "mat-paginator", [["class", "mat-paginator"]], null, [[null, "page"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPageChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatPaginator_0, i7.RenderType_MatPaginator)), i1.ɵdid(6, 245760, null, 0, i8.MatPaginator, [i8.MatPaginatorIntl, i1.ChangeDetectorRef], { pageIndex: [0, "pageIndex"], length: [1, "length"], pageSize: [2, "pageSize"], pageSizeOptions: [3, "pageSizeOptions"], showFirstLastButtons: [4, "showFirstLastButtons"] }, { page: "page" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageListComponent_2)), i1.ɵdid(8, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 4, "div", [["class", "ff-image-list-page-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "mdc-image-list", [["class", "masonry-image-list mdc-image-list"]], [[2, "mdc-image-list--masonry", null], [2, "mdc-image-list--with-text-protection", null]], null, null, i9.View_MdcImageList_0, i9.RenderType_MdcImageList)), i1.ɵdid(11, 49152, [["demomasonry", 4]], 0, i2.MdcImageList, [i1.ElementRef], { masonry: [0, "masonry"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_ImageListComponent_3)), i1.ɵdid(13, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 2, "div", [["class", "ff-paginator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 1, "mat-paginator", [["class", "mat-paginator"]], null, [[null, "page"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("page" === en)) {
        var pd_0 = (_co.onPageChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_MatPaginator_0, i7.RenderType_MatPaginator)), i1.ɵdid(16, 245760, null, 0, i8.MatPaginator, [i8.MatPaginatorIntl, i1.ChangeDetectorRef], { pageIndex: [0, "pageIndex"], length: [1, "length"], pageSize: [2, "pageSize"], pageSizeOptions: [3, "pageSizeOptions"], showFirstLastButtons: [4, "showFirstLastButtons"] }, { page: "page" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = " center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = ""; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.searchParams.offset; var currVal_4 = _co.noOfImages; var currVal_5 = _co.searchParams.limit; var currVal_6 = _co.pageSizeOptions; var currVal_7 = true; _ck(_v, 6, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.searchParams.category_label; _ck(_v, 8, 0, currVal_8); var currVal_11 = true; _ck(_v, 11, 0, currVal_11); var currVal_12 = _co.masonryImages; _ck(_v, 13, 0, currVal_12); var currVal_13 = _co.searchParams.offset; var currVal_14 = _co.noOfImages; var currVal_15 = _co.searchParams.limit; var currVal_16 = _co.pageSizeOptions; var currVal_17 = true; _ck(_v, 16, 0, currVal_13, currVal_14, currVal_15, currVal_16, currVal_17); }, function (_ck, _v) { var currVal_9 = i1.ɵnov(_v, 11).masonry; var currVal_10 = i1.ɵnov(_v, 11).textProtection; _ck(_v, 10, 0, currVal_9, currVal_10); }); }
function View_ImageListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["fxFlex", ""], ["fxLayout", "column"], ["fxLayoutAlign", " center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutStyleBuilder], i6.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i6.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(3, 671744, null, 0, i5.DefaultFlexDirective, [i1.ElementRef, i6.StyleUtils, i6.LAYOUT_CONFIG, i5.FlexStyleBuilder, i6.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i10.View_MatSpinner_0, i10.RenderType_MatSpinner)), i1.ɵdid(5, 49152, null, 0, i11.MatSpinner, [i1.ElementRef, i12.Platform, [2, i4.DOCUMENT], [2, i13.ANIMATION_MODULE_TYPE], i11.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { diameter: [0, "diameter"] }, null)], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = " center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = ""; _ck(_v, 3, 0, currVal_2); var currVal_6 = 40; _ck(_v, 5, 0, currVal_6); }, function (_ck, _v) { var currVal_3 = i1.ɵnov(_v, 5)._noopAnimations; var currVal_4 = i1.ɵnov(_v, 5).diameter; var currVal_5 = i1.ɵnov(_v, 5).diameter; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5); }); }
export function View_ImageListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageListComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImageListComponent_4)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.masonryImages; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.masonryImages; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ImageListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-image-list", [], null, null, null, View_ImageListComponent_0, RenderType_ImageListComponent)), i1.ɵdid(1, 114688, null, 0, i14.ImageListComponent, [i15.ImageListService, i3.Router, i3.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImageListComponentNgFactory = i1.ɵccf("app-image-list", i14.ImageListComponent, View_ImageListComponent_Host_0, {}, {}, []);
export { ImageListComponentNgFactory as ImageListComponentNgFactory };
