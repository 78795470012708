/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./dialog.component";
import * as i3 from "@angular/material/dialog";
import * as i4 from "../upload.service";
var styles_DialogComponent = [i0.styles];
var RenderType_DialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogComponent, data: {} });
export { RenderType_DialogComponent as RenderType_DialogComponent };
export function View_DialogComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { file: 0 })], null, null); }
export function View_DialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog", [], null, null, null, View_DialogComponent_0, RenderType_DialogComponent)), i1.ɵdid(1, 49152, null, 0, i2.DialogComponent, [i3.MatDialogRef, i4.UploadService], null, null)], null, null); }
var DialogComponentNgFactory = i1.ɵccf("app-dialog", i2.DialogComponent, View_DialogComponent_Host_0, {}, {}, []);
export { DialogComponentNgFactory as DialogComponentNgFactory };
