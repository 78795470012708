<h2 mat-dialog-title>Er du sikker på at du vil slette denne brukeren?</h2>
<mat-dialog-content class="mat-typography">
    <div>
        Brukeren <strong>{{data.firstname}} {{data.lastname}}</strong> og <strong>{{data.foto_count}}</strong> bilder vil bli slettet.
    </div>
    <br>
    <div>
        NB! Denne handlingen kan ikke angres.
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="close()">Avbryt</button>
    <button mat-raised-button (click)="confirmAction()" color="primary">Slett</button>
</mat-dialog-actions>
