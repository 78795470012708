/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./icon-snack-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "./icon-snack-bar.component";
import * as i7 from "@angular/material/snack-bar";
var styles_IconSnackBarComponent = [i0.styles];
var RenderType_IconSnackBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IconSnackBarComponent, data: {} });
export { RenderType_IconSnackBarComponent as RenderType_IconSnackBarComponent };
export function View_IconSnackBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["fxLayout", "row"], ["fxLayoutAlign", "start center"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(4, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, null], [2, i5.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(5, 0, ["", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [["style", "margin-left: 16px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start center"; _ck(_v, 2, 0, currVal_1); _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵnov(_v, 4).inline; var currVal_3 = (((i1.ɵnov(_v, 4).color !== "primary") && (i1.ɵnov(_v, 4).color !== "accent")) && (i1.ɵnov(_v, 4).color !== "warn")); _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = ((_co.data == null) ? null : _co.data.icon); _ck(_v, 5, 0, currVal_4); var currVal_5 = ((_co.data == null) ? null : _co.data.message); _ck(_v, 7, 0, currVal_5); }); }
export function View_IconSnackBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-icon-snack-bar", [], null, null, null, View_IconSnackBarComponent_0, RenderType_IconSnackBarComponent)), i1.ɵdid(1, 114688, null, 0, i6.IconSnackBarComponent, [i7.MAT_SNACK_BAR_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconSnackBarComponentNgFactory = i1.ɵccf("app-icon-snack-bar", i6.IconSnackBarComponent, View_IconSnackBarComponent_Host_0, {}, {}, []);
export { IconSnackBarComponentNgFactory as IconSnackBarComponentNgFactory };
