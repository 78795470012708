import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, RequiredValidator } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { IconSnackBarComponent } from '../icon-snack-bar/icon-snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../content.service';
import { CategoriesService } from '../categories.service';
import { AuthService } from '../auth.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-single-photo',
  templateUrl: './single-photo.component.html',
  styleUrls: ['./single-photo.component.scss']
})
export class SinglePhotoComponent implements OnInit {

  // visible = true;
  // selectable = true;
  // removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  categoryCtrl = new FormControl();
  filteredCategories;
  categories;
  allCategories;
  editable: boolean = false;
  currentUserId = null;
  adminuser = false;

  content;
  contentForm: FormGroup;

  
  private sub: any;

  @ViewChild('categoryInput', { static: false }) categoryInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  constructor(
    private contentService: ContentService,
    private categoriesService: CategoriesService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private meta: Meta
  ) {
  }

  ngOnInit() {
    this.authService.sessionInfo$
      .subscribe(sessionInfo => {
        if (sessionInfo && sessionInfo.user) {
          if (sessionInfo.user.id) {
            this.currentUserId = sessionInfo.user.id;
          } else {
            this.currentUserId = null;
          }
          this.adminuser = sessionInfo.user.adminuser;
        }
      })

    this.categoriesService.getCategories()
      .subscribe(categories => {
        this.allCategories = categories;
        this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
          startWith(null),
          map(value => this._filterGroup(value)));
      });


    this.sub = this.activatedRoute.params.subscribe(params => {
      let id = +params['id'];
      this.contentService.getImageDetails(id).subscribe(photo => {
        this.content = photo;
        this.contentForm = this.fb.group({
          id: photo.id,
          text: [photo.text, Validators.required],
          imageTitle: photo.imageTitle,
          archiveGroup: this.fb.group({
            is_photo: photo.is_photo,
            is_art: photo.is_art,
            is_illustration: photo.is_illustration
          }, { validator: this.oneIsChecked }),
          categories: this.fb.array(
            photo.categories
          )
        });
        this.categories = photo.categories;

        let categoriesString = '';
        this.categories.map(category => {
          categoriesString += category.name + ', ';
        })
        this.meta.addTag({ name: 'keywords', content: photo.text + " " + categoriesString });
      });
    });
  }
  oneIsChecked(group: FormGroup) {
    return Object.keys(group.controls).some(x => group.controls[x].value === true) ?
      null : { notValid: true }
  }

  get categoriesFormArray() {
    return this.contentForm.get('categories') as FormArray;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      if ((value || '')) {
        this.categories.push(value);
      }

      if (input) {
        input.value = '';
      }

      this.categoryCtrl.setValue(null);
    }
  }

  remove(category): void {
    const index = this.categories.indexOf(category);

    if (index >= 0) {
      this.categories.splice(index, 1);
      this.categoriesFormArray.removeAt(index);
      this.contentForm.markAsDirty();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const cat = {name: event.option.value.label, id: event.option.value.id};
    this.categories.push(cat);
    this.categoryInput.nativeElement.value = '';
    this.categoryCtrl.setValue(null);

    this.categoriesFormArray.push(this.fb.control(cat));
    this.contentForm.markAsDirty();
  }


  private _filterGroup(value: string) {
    if (value && typeof value === 'string') {
      return this.allCategories
        .map(category => ({ label: category.label, id: category.id, children: this._filter(category.children, value) }))
        .filter(category => category.children.length > 0);
    }

    return this.allCategories;
  }

  private _filter = (opt , value)  => {
    const filterValue = value.toLowerCase();
      return opt.filter(item => item.label.toLowerCase().indexOf(filterValue) === 0);
  };


  onSubmit() {
    const imageDetails = {
      is_art: this.contentForm.value.archiveGroup.is_art,
      is_illustration: this.contentForm.value.archiveGroup.is_illustration,
      is_photo: this.contentForm.value.archiveGroup.is_photo,
      categories: this.contentForm.value.categories,
      id: this.contentForm.value.id,
      imageTitle: this.contentForm.value.imageTitle,
      text: this.contentForm.value.text
    };
    this.contentService.saveImageDetails(imageDetails)
      .subscribe(ret => {
        //this._snackBar.open('Endringer er lagret', '', {duration: 2000});
        this._snackBar.openFromComponent(IconSnackBarComponent,
          {
            data: { message: 'Endringer er lagret!', icon: 'check_circle'},
            duration: 2000
          });
        this.contentForm.markAsPristine();
      })
  }

  searchCategory(id, label) {
    this.router.navigate(['/search'], {queryParams: {'category_id': id, 'category_label': label, 'offset': 0}});
  }

  deleteImage(content) {
    this.contentService.deleteImage(content)
      .subscribe(res => {
        this._snackBar.open(content.imageTitle + ' ble slettet', '', { duration: 2000 });
        this.router.navigate(['/']);
      });
  }

}
