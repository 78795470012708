import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MdcImageListModule } from '@angular-mdc/web';
import { AppMaterialModule } from './app-material/app-material.module';
import { QuillModule } from 'ngx-quill';
import { UploadModule } from './upload/upload.module';

import { ImageListComponent } from './image-list/image-list.component';
import { UserBannerComponent } from './user-banner/user-banner.component';
import { EditCategoriesComponent } from './edit-categories/edit-categories.component';
import { SearchComponent } from './search/search.component';
import { CategoriesComponent } from './categories/categories.component';
import { SinglePhotoComponent } from './single-photo/single-photo.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { UsersComponent } from './users/users.component';
import { ArticlePageComponent } from './article-page/article-page.component';
import { MyImagesComponent } from './my-images/my-images.component';
import { IconSnackBarComponent } from './icon-snack-bar/icon-snack-bar.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { AuthService } from './auth.service';
import { DeleteUserDialogComponent } from './delete-user-dialog/delete-user-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    ImageListComponent,
    UserBannerComponent,
    EditCategoriesComponent,
    SearchComponent,
    CategoriesComponent,
    SinglePhotoComponent,
    LoginDialogComponent,
    UsersComponent,
    ArticlePageComponent,
    MyImagesComponent,
    IconSnackBarComponent,
    SafeHtmlPipe,
    DeleteUserDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MdcImageListModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppMaterialModule,
    UploadModule,
    QuillModule.forRoot()
  ],
  entryComponents: [LoginDialogComponent, IconSnackBarComponent, DeleteUserDialogComponent],
  providers: [
    {
      provide: 'backendServerUrl',
      useValue: environment.BACKEND_SERVER_URL
    },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthService,
        multi: true
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
