import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { DeleteUserDialogComponent } from '../delete-user-dialog/delete-user-dialog.component';

import { UsersService } from '../users.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  usersForm: FormGroup;
  newUserForm: FormGroup;
  isAdminUser = false;
  @ViewChild(MatTable, { static: false }) table: MatTable<any>;

  displayedColumns: string[] = ['id', 'username', 'email', 'firstname', 'lastname', 'fotoCount', 'delete', 'password', 'save'];
  dataSource: MatTableDataSource<any>;
  constructor(
    private authService: AuthService,
    private usersService: UsersService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    // this.isAdminUser = this.authService.isAdminUser();
    this.usersService.getUsers()
      .subscribe( users => {
        this.usersForm = this.initUsersForm(users);
        this.dataSource = new MatTableDataSource((this.usersForm.get('users') as FormArray).controls);
      });

    this.authService.sessionInfo$
      .subscribe(sessionInfo => {
        if (sessionInfo && sessionInfo.user) {
          this.isAdminUser = sessionInfo.user.adminuser;
        }
      })

  }

  initUsersForm(users) {
    return this.fb.group({
      users: this.fb.array(
        users.map(user => this.fb.group({
            id: user.id,
            username: user.username,
            email: [user.email, [Validators.email]],
            firstname: user.firstname,
            lastname: user.lastname,
            password: '',
            /* password: ['', [Validators.required]], */
            fotoCount: user.foto_count,
            editPassword: false,
            isDirty: false
        })
      ))
    });
  }

  get users(): FormArray {
    return (this.usersForm.get('users') as FormArray);
  }

  initNewUserForm() {
    this.newUserForm = this.fb.group({
      username: '',
      email: ['', [Validators.email]],
      firstname: '',
      lastname: '',
      password: ['', [Validators.required]]
    });

    this.newUserForm.valueChanges
      .subscribe(changes => {
      });
  }

  saveNewUserData() {
    this.usersService.createNewUser(this.newUserForm.value)
    .subscribe(res => {
      this.openSnackBar(res.systemuser.firstname + ' ' + res.systemuser.lastname + ' er nå lagret ', '');
      this.newUserForm = null;
      this.usersService.getUsers()
        .subscribe(users => {
          this.usersForm = this.initUsersForm(users);
          this.dataSource = new MatTableDataSource((this.usersForm.get('users') as FormArray).controls);
        });
    })
  }

  saveUserData(userIdx) {
    const userData = this.users.get(userIdx.toString()).value;
    if (!userData.password || userData.password.length === 0) {
      delete userData.password;
    }
    delete userData.editPassword;
    this.usersService.saveUserData(userData.id, userData)
      .subscribe(res => {
        this.openSnackBar(res.systemuser.firstname + ' ' + res.systemuser.lastname + ' er nå lagret ', '');
        this.users.get(userIdx.toString()).markAsPristine();
      })
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  deleteUser(userIdx) {
    const userData = this.users.get(userIdx.toString()).value;
    const dialogRef = this.dialog.open(DeleteUserDialogComponent, {
      width: '450px',
      data: { firstname: userData.firstname, lastname: userData.lastname, foto_count: userData.fotoCount }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'confirmed') {
        this.usersService.deleteUser(userData.id)
          .subscribe(res => {
            this._snackBar.open('Brukeren ble slettet', '', { duration: 2000 });
            this.usersService.getUsers()
              .subscribe(users => {
                this.usersForm = this.initUsersForm(users);
                this.dataSource = new MatTableDataSource((this.usersForm.get('users') as FormArray).controls);
              });
          });
      } else {
        this._snackBar.open('Brukeren ble IKKE slettet', '', { duration: 2000 });
      }
    });
  }

}
