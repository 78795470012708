import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImageListComponent } from './image-list/image-list.component';
import { SinglePhotoComponent } from './single-photo/single-photo.component';
import { UsersComponent } from './users/users.component';
import { MyImagesComponent } from './my-images/my-images.component';
import { ArticlePageComponent } from './article-page/article-page.component';

const routes: Routes = [
  {
    path: '',
    component: ImageListComponent
  },
  {
    path: 'search',
    component: ImageListComponent
  },
  {
    path: 'single-photo/:id',
    component: SinglePhotoComponent
  },
  {
    path: 'users',
    component: UsersComponent
  },
  {
    path: 'my-images',
    component: MyImagesComponent
  },
  {
    path: 'article/:label',
    component: ArticlePageComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
