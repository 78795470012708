import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ContentService } from './content.service';

export interface Category {
  id?: string | number;
  label: string;
  children?: Category[];
  parent?: string | number;
}


@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  categories = [];

  constructor(
    private contentService: ContentService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.contentService.fetchContent('/categories').subscribe(categories => {
      this.categories = categories;
    });
  }

  private errorHandler(err) {
    if (err.error && err.error.message) {
      alert(err.error.message);
    } else if (err.error && err.error._issues) {
      alert(
        Object.entries(err.error._issues).map(entry => {
          return entry[0] + ' : ' + entry[1];
        }).join('\n')
      );
    } else {
      alert('Feil på url: ' + err.url + '\n Status code: ' + err.status + '\n' + err.message);
    }
    return throwError(err);
  }

  getCategories() {
    //return of(this.categories);
    return this.contentService.fetchContent('/categories');
  }

  deleteCategory(category) {
    return this.http.delete<any>(environment.BACKEND_SERVER_URL + '/category/' + category.id, { withCredentials: true })
    .pipe(
      catchError(this.errorHandler)
    );
  }

  addCategory(category) {
    if (!category.id) {
      delete category.id;
    }
    return this.http.post<any>(environment.BACKEND_SERVER_URL + '/category', category, { withCredentials: true })
    .pipe(
      catchError(this.errorHandler)
    );
  }

  updateCategory(category) {
    return this.http.post<any>(environment.BACKEND_SERVER_URL + '/category/' + category.id, category, { withCredentials: true })
    .pipe(
      catchError(this.errorHandler)
    );
  }
}
