import { ContentService } from './content.service';
import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

interface SessionInfo {
  logged_in: boolean;
  user: UserInfo;
}

interface UserInfo {
  id: number;
  username: string;
  fullname: string;
  firstname: string;
  lastname: string;
  email: string;
  phone_mobile: string;
  last_login: string;
  reg_date: string;
  adminuser: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements HttpInterceptor {

  constructor(
    private http: HttpClient,
    private contentService: ContentService,
    private router: Router,
    private _snackBar: MatSnackBar
  ) { }

  private sessionInfo;
  private userInfo: UserInfo;
  public sessionInfo$: BehaviorSubject<SessionInfo> = new BehaviorSubject(null);

  intercept(req: HttpRequest<any>, next: HttpHandler):
    Observable<HttpEvent<any>> {
      return next.handle(req)
        .pipe(
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                // this.sessionInfo = null;
                this.sessionInfo$.next(null);
                this.router.navigate(['']);
                this._snackBar.open('Bruker er ikke innlogget med nødvendige rettigheter' , 'lukk');
              }
            }
            return throwError(err);
          })
        );
  }

  private errorHandler(err) {
    if (err.error && err.error.message) {
      alert(err.error.message);
    } else if (err.error && err.error._issues) {
      alert(
        Object.entries(err.error._issues).map(entry => {
          return entry[0] + ' : ' + entry[1];
        }).join('\n')
      );
    } else {
      alert('Feil på url: ' + err.url + '\n Status code: ' + err.status + '\n' + err.message);
    }
    return throwError(err);
  }

  public fetchSessionInfoOnInit() {
    return this.http.get(environment.BACKEND_SERVER_URL + '/sessioninfo', { withCredentials: true })
      .pipe(
        catchError(this.errorHandler),
      )
      .subscribe((sessionInfo: SessionInfo) => {
        this.sessionInfo$.next(sessionInfo);
      })
  }

  public login(userInfo) {
    const content = {
      username: userInfo.username,
      password: userInfo.password
    };
    const httpOptions = {
      withCredentials: true
    };
    return this.http.post(environment.BACKEND_SERVER_URL + '/login', content, httpOptions)
      .pipe(
        tap((sessionInfo: SessionInfo) => {
          this.sessionInfo$.next(sessionInfo);
        })
      )
  }

  public resetPassword(username) {
    const content = {
      username: username
    };
    const httpOptions = {
      withCredentials: true
    };
    return this.http.post(environment.BACKEND_SERVER_URL + '/iforgot', content, httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public setPassword(token, password) {
    const content = {
      token: token,
      password: password,
    };
    const httpOptions = {
      withCredentials: true
    };
    return this.http.post(environment.BACKEND_SERVER_URL + '/resetpassword', content, httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public logout() {
    return this.http.get(environment.BACKEND_SERVER_URL + '/logout', { withCredentials: true })
      .pipe(
        catchError(this.errorHandler)
      )
      .subscribe(
        res => {
          this.sessionInfo$.next(null);
          this.router.navigate(['/']);
        },
        err => {
          console.error('Could not logout', err);
        }
      );
  }

  public authenticate(uname, pwd) {
    return this.login({ username: uname, password: pwd });
  }

  public getUserInfo() {
    return this.sessionInfo$
    .pipe(
      map((sessionInfo: SessionInfo) => {
        return sessionInfo? sessionInfo.user: null;
      })
    )
  }

  public isAuthenticated() {
    return this.sessionInfo$
    .pipe(
      map((sessionInfo: SessionInfo) => {
        return sessionInfo && sessionInfo.logged_in;
      })
    )
  }

  isAdminUser() {
    return this.sessionInfo$
      .pipe(
        map((sessionInfo: SessionInfo) => {
          if (sessionInfo  && sessionInfo.user && sessionInfo.user.adminuser) {
            return true;
          } else {
            return false;
          }
        })
      )

  }

}
