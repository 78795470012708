import { OnInit } from '@angular/core';
import { MatTable } from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { DeleteUserDialogComponent } from '../delete-user-dialog/delete-user-dialog.component';
var UsersComponent = /** @class */ (function () {
    function UsersComponent(authService, usersService, fb, _snackBar, dialog) {
        this.authService = authService;
        this.usersService = usersService;
        this.fb = fb;
        this._snackBar = _snackBar;
        this.dialog = dialog;
        this.isAdminUser = false;
        this.displayedColumns = ['id', 'username', 'email', 'firstname', 'lastname', 'fotoCount', 'delete', 'password', 'save'];
    }
    UsersComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this.isAdminUser = this.authService.isAdminUser();
        this.usersService.getUsers()
            .subscribe(function (users) {
            _this.usersForm = _this.initUsersForm(users);
            _this.dataSource = new MatTableDataSource(_this.usersForm.get('users').controls);
        });
        this.authService.sessionInfo$
            .subscribe(function (sessionInfo) {
            if (sessionInfo && sessionInfo.user) {
                _this.isAdminUser = sessionInfo.user.adminuser;
            }
        });
    };
    UsersComponent.prototype.initUsersForm = function (users) {
        var _this = this;
        return this.fb.group({
            users: this.fb.array(users.map(function (user) { return _this.fb.group({
                id: user.id,
                username: user.username,
                email: [user.email, [Validators.email]],
                firstname: user.firstname,
                lastname: user.lastname,
                password: '',
                /* password: ['', [Validators.required]], */
                fotoCount: user.foto_count,
                editPassword: false,
                isDirty: false
            }); }))
        });
    };
    Object.defineProperty(UsersComponent.prototype, "users", {
        get: function () {
            return this.usersForm.get('users');
        },
        enumerable: true,
        configurable: true
    });
    UsersComponent.prototype.initNewUserForm = function () {
        this.newUserForm = this.fb.group({
            username: '',
            email: ['', [Validators.email]],
            firstname: '',
            lastname: '',
            password: ['', [Validators.required]]
        });
        this.newUserForm.valueChanges
            .subscribe(function (changes) {
        });
    };
    UsersComponent.prototype.saveNewUserData = function () {
        var _this = this;
        this.usersService.createNewUser(this.newUserForm.value)
            .subscribe(function (res) {
            _this.openSnackBar(res.systemuser.firstname + ' ' + res.systemuser.lastname + ' er nå lagret ', '');
            _this.newUserForm = null;
            _this.usersService.getUsers()
                .subscribe(function (users) {
                _this.usersForm = _this.initUsersForm(users);
                _this.dataSource = new MatTableDataSource(_this.usersForm.get('users').controls);
            });
        });
    };
    UsersComponent.prototype.saveUserData = function (userIdx) {
        var _this = this;
        var userData = this.users.get(userIdx.toString()).value;
        if (!userData.password || userData.password.length === 0) {
            delete userData.password;
        }
        delete userData.editPassword;
        this.usersService.saveUserData(userData.id, userData)
            .subscribe(function (res) {
            _this.openSnackBar(res.systemuser.firstname + ' ' + res.systemuser.lastname + ' er nå lagret ', '');
            _this.users.get(userIdx.toString()).markAsPristine();
        });
    };
    UsersComponent.prototype.openSnackBar = function (message, action) {
        this._snackBar.open(message, action, {
            duration: 2000,
        });
    };
    UsersComponent.prototype.deleteUser = function (userIdx) {
        var _this = this;
        var userData = this.users.get(userIdx.toString()).value;
        var dialogRef = this.dialog.open(DeleteUserDialogComponent, {
            width: '450px',
            data: { firstname: userData.firstname, lastname: userData.lastname, foto_count: userData.fotoCount }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result === 'confirmed') {
                _this.usersService.deleteUser(userData.id)
                    .subscribe(function (res) {
                    _this._snackBar.open('Brukeren ble slettet', '', { duration: 2000 });
                    _this.usersService.getUsers()
                        .subscribe(function (users) {
                        _this.usersForm = _this.initUsersForm(users);
                        _this.dataSource = new MatTableDataSource(_this.usersForm.get('users').controls);
                    });
                });
            }
            else {
                _this._snackBar.open('Brukeren ble IKKE slettet', '', { duration: 2000 });
            }
        });
    };
    return UsersComponent;
}());
export { UsersComponent };
