import * as i0 from "@angular/core";
import * as i1 from "./content.service";
var ImageListService = /** @class */ (function () {
    function ImageListService(contentService) {
        this.contentService = contentService;
    }
    ImageListService.prototype.getImagesByCategory = function (categoryId, searchParams) {
        var queryUrl = '/foto/category/' + categoryId;
        return this.contentService.fetchContentWithQueryParams(queryUrl, searchParams);
    };
    ImageListService.prototype.getAllPhotoes = function (searchParams) {
        var queryUrl = '/foto';
        return this.contentService.fetchContentWithQueryParams(queryUrl, searchParams);
    };
    ImageListService.prototype.getImagesBySearchParams = function (searchParams) {
        var queryUrl = '/foto/search';
        return this.contentService.fetchContentWithQueryParams(queryUrl, searchParams);
    };
    ImageListService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ImageListService_Factory() { return new ImageListService(i0.ɵɵinject(i1.ContentService)); }, token: ImageListService, providedIn: "root" });
    return ImageListService;
}());
export { ImageListService };
