import { Component, ViewChild, OnInit } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginDialogComponent, LoginDialogData } from './login-dialog/login-dialog.component';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'spa';
  mode: string = 'side';
  opened: boolean = true;
  @ViewChild('sidenav', { static: false }) matSidenav: MatSidenav;
  
  constructor(
    private media: MediaObserver, 
    public auth: AuthService, 
    private router: Router, 
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
  ) {
    this.media.media$.subscribe((mediaChange: MediaChange) => {
      this.mode = this.getMode(mediaChange);
      this.opened = this.getOpened(mediaChange);
    });
  }

  ngOnInit() {
    this.auth.fetchSessionInfoOnInit();
  }

  openLoginDialog() {
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      width: '450px',
      data: { username: '', password: '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.username === null && result.password === null) {

      } else {
        this.auth.authenticate(result.username, result.password)
          // .pipe(
          //   catchError(this.errorHandler)
          // )
          .subscribe(res => {
            this.router.navigate(['/']);
          },
            err => {
              this._snackBar.open('Feil brukernavn eller passord', '', { duration: 2000 });
              this.openLoginDialog();
            });
      }

    });
  }

  private errorHandler(error) {
    if (error.statusText === 'UNAUTHORIZED') {
    }
    return throwError(error);
  }

  private getMode(mediaChange: MediaChange): string {
    // set mode based on a breakpoint
    if (this.media.isActive('gt-sm')) {
      return 'side';
    } else {
      return 'over';
    }
  }

  // open/close as needed
  private getOpened(mediaChange: MediaChange): boolean {
    if (this.media.isActive('gt-sm')) {
      return true;
    } else {
      return false;
    }
  }

  logout() {
    this.auth.logout();
  }

  triggerCloseNav() {
    if (!this.media.isActive('gt-sm')) {
      this.matSidenav.toggle();
    }
  }

}



