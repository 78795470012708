import { Component, OnInit, Renderer2 } from '@angular/core';
import { ContentService } from '../content.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../auth.service';
import { IconSnackBarComponent } from '../icon-snack-bar/icon-snack-bar.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.scss']
})
export class ArticlePageComponent implements OnInit {

  content;
  contentForm: FormGroup;
  isEditable: boolean;
  editMode: boolean = false;

  previousUrl;
  currentUrl;

  constructor(
    private contentService: ContentService,
    private router: ActivatedRoute,
    private rout: Router,
    private authServic: AuthService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private renderer: Renderer2
    ) {
    }


  ngOnInit() {
   this.authServic.isAdminUser()
    .subscribe( ret => {
      this.isEditable = ret;
    });

    this.router.params.subscribe(params => {
      this.contentService.getArticle(params.label)
        .subscribe(content => {
          this.content =  content;


          /* Add label as body class on router change */
          this.renderer.addClass(document.body, this.content.label);
          this.previousUrl = this.content.label;
          this.rout.events
            .subscribe((event) => {
              if (event instanceof NavigationStart) {
                if (this.previousUrl) {
                  this.renderer.removeClass(document.body, this.previousUrl);
                }
                if (this.currentUrl) {
                  this.renderer.addClass(document.body, this.currentUrl);
                }
                this.previousUrl = this.currentUrl;
              }
            });

          if (this.isEditable) {
            this.contentForm = this.fb.group({
              tittel: this.content.tittel,
              tekst: this.content.tekst,
              label: this.content.label
            });
          }
        });
    });
  }
  /* removeBodyClass() {
    this.renderer.removeClass(document.body, this.content.label);
  }
  ngOnDestroy() {
    this.renderer.removeClass(document.body, this.content.label);
  } */

  onSubmit() {
    this.contentService.updateArticle(this.content.id, this.contentForm.value)
    .subscribe(res => {
      this.contentForm.markAsPristine();
      this._snackBar.openFromComponent(IconSnackBarComponent,
        {
          data: { message: 'Endringer er lagret!', icon: 'check_circle' },
          duration: 2000
        });
    })
  }


}
