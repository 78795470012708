<!-- <div class="ff-article-page-container" fxLayout="column" fxFlex fxLayoutAlign=" center">
    <section class="ff-article-page-content mat-typography">
        <h1>How to search for images in Fotofil.</h1>
        <h1 class="ff-custom-title">How to search for images in Fotofil.</h1>
        <h2>Photography, Fine Art & Illustrations.</h2>
        <p>Start with choosing what kind of images/expressions you would like to see. We have three different archives: Photography
        (including old photographs), Fine Art and Illustrations.</p>
        <p>Perhaps you would like to see images from all three archives, or maybe one of them, or two... Simply click on the box to
        the left of the archive(s) from where you want to see images from.</p>
        <h2>Collect images you select in your own folder: "My Folder".</h2>
        <p>To the right of our logo you will find "My Folder", where you can store selected images in your own project folder as
        you work your way through our archives. When you leave Fotofil your image collection will remain there until you return
        a day or week later. (You must have "Cookies enabled" in your browser).</p>
        <h2>Keyword search.</h2>
        <p>You may decide to search for images using your own subject description or keyword. Then, simply enter your keyword(s).
        When you use keywords, try first using the singular form. For example, enter "dog" instead of “dogs.” If you are not
        satisified with the search results, then try the plural form to see if you find additional images. Using keywords it is
        smart to think of synonyms and other words associated with your subject.</p>
        <h2>How to order.</h2>
        <p>You can order images by clicking on the "Order Now" from "My Folder" or simply click on an image and then click on
        "Order Now" from there.</p>
        <p>You will not be asked to pay online. We will first communicate to find out your needs for the images and discuss the
        price with you. Of course you are most welcome to make us a phone call directly or send an ordinary email. When you
        contact us, please reference the registration codes of the images which interest you so we can identify them. An image´s
        registration code is found directly underneath the image in the archive.</p>
    </section>
</div> -->
<div class="ff-article-page-container" fxLayout="column" fxFlex fxLayoutAlign=" center"  *ngIf="content">

    <!-- <mat-slide-toggle *ngIf="isEditable" class="ff-btn-typo" color="secondary" [(ngModel)]="editMode">Rediger</mat-slide-toggle> -->

    <section class="ff-article-page-content mat-typography" *ngIf="!isEditable && content">
        <h1>{{content.tittel}}</h1>
        <div class="ff-article-page-content__text-and-image">
            <div class="ff-article-page-content__text" [innerHTML]="content.tekst | safeHtml"></div>
            <div class="ff-article-page-content__bg-image"></div>
        </div>
    </section>
    <section class="ff-article-page-content mat-typography" *ngIf="contentForm && isEditable" style="width: 100%">
        <!-- <div>Nøkkel: "{{content.label}}"</div><br> -->

        <form [formGroup]="contentForm" (ngSubmit)="onSubmit()">
            <input type="hidden" formControlName="label">
        
            <mat-form-field appearance="outline">
                <mat-label>Overskrift</mat-label>
                <input matInput placeholder="Skriv en overskrift" formControlName="tittel">
            </mat-form-field>

            <quill-editor
                [formControl]="contentForm.controls['tekst']"
                [formats]="['header', 'bold', 'italic', 'link']"
                [modules]="{ toolbar: [{header: 2}, 'bold', 'italic', 'link'] }"
                [sanitize]="true" placeholder="" theme="snow" >
            </quill-editor>

            <br><br>
            <div>
                <button mat-button mat-raised-button color="primary" disabled="{{contentForm.pristine}}" type="submit">Lagre</button>
            </div>

        </form>
    </section>


    <!-- {{contentForm.value | json}} -->
        
</div>

