import { environment } from '../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
// import { environment } from '@env/environment';
var ContentService = /** @class */ (function () {
    function ContentService(http) {
        this.http = http;
    }
    ContentService.prototype.getSearchResult = function (url, field, query, modifier) {
        var filter;
        if (modifier) {
            filter = "{\"" + field + "\":\"ilike(\\\"%" + query + "%\\\")\"," + modifier + "}";
        }
        else {
            filter = "{\"" + field + "\":\"ilike(\\\"%" + query + "%\\\")\"}";
        }
        var httpOptions = {
            withCredentials: true,
            params: { 'where': filter }
        };
        return this.http.get(environment.BACKEND_SERVER_URL + url, httpOptions);
    };
    ContentService.prototype.errorHandler = function (err) {
        if (err.error && err.error.message) {
            alert(err.error.message);
        }
        else if (err.error && err.error._issues) {
            alert(Object.entries(err.error._issues).map(function (entry) {
                return entry[0] + ' : ' + entry[1];
            }).join('\n'));
        }
        else {
            alert('Feil på url: ' + err.url + '\n Status code: ' + err.status + '\n' + err.message);
        }
        return throwError(err);
    };
    ContentService.prototype.fetchSisteBilder = function () {
        var params = {};
        return this.http.get(environment.BACKEND_SERVER_URL + '/foto', { params: params, withCredentials: false })
            .pipe(catchError(this.errorHandler));
    };
    ContentService.prototype.getImageDetails = function (photoid) {
        var params = {};
        return this.http.get(environment.BACKEND_SERVER_URL + '/foto/' + photoid, { params: params, withCredentials: false })
            .pipe(catchError(this.errorHandler));
    };
    ContentService.prototype.saveImageDetails = function (photo) {
        return this.http.post(environment.BACKEND_SERVER_URL + '/foto/' + photo.id, photo, { withCredentials: true })
            .pipe(catchError(this.errorHandler));
    };
    ContentService.prototype.fetchContentWithQueryParams = function (url, data) {
        return this.http.get(environment.BACKEND_SERVER_URL + url, { params: data, withCredentials: true })
            .pipe(catchError(this.errorHandler));
    };
    ContentService.prototype.fetchContent = function (url) {
        // const backend   =environment.backendServerUrl ? environment.backendServerUrl : '';
        return this.http.get(environment.BACKEND_SERVER_URL + url, { withCredentials: true })
            .pipe(catchError(this.errorHandler));
    };
    ContentService.prototype.postContent = function (url, content) {
        return this.http.post(environment.BACKEND_SERVER_URL + url, content, { withCredentials: true })
            .pipe(tap(function (res) {
            if (res && res.warnings && res.warnings.length) {
                alert(res.warnings.map(function (warnings) { return warnings.txt; }).join('\n'));
            }
        }), catchError(this.errorHandler));
    };
    ContentService.prototype.putContent = function (url, content) {
        return this.http.put(environment.BACKEND_SERVER_URL + url, content, { withCredentials: true })
            .pipe(catchError(this.errorHandler));
    };
    ContentService.prototype.deleteContent = function (url) {
        return this.http.delete(environment.BACKEND_SERVER_URL + url, { withCredentials: true })
            .pipe(catchError(this.errorHandler));
    };
    ContentService.prototype.updateContent = function (url, content, eTag) {
        var httpOptions;
        if (eTag) {
            httpOptions = {
                withCredentials: true,
                headers: new HttpHeaders({
                    'If-Match': eTag
                    // ,
                    // 'Content-type': 'application/x-www-form -urlencoded; charset= UTF-8'
                    // 'Content-Type': // 'application/json',
                    // 'application/x-www-form-urlencoded; charset=UTF-8'
                })
            };
        }
        else {
            httpOptions = { withCredentials: true };
        }
        return this.http.patch(environment.BACKEND_SERVER_URL + url, content, httpOptions)
            .pipe(catchError(this.errorHandler));
    };
    ContentService.prototype.search = function (params) {
        return this.http.get(environment.BACKEND_SERVER_URL + '/foto/search', { params: params, withCredentials: false })
            .pipe(catchError(this.errorHandler));
    };
    ContentService.prototype.getArticle = function (label) {
        var params = {};
        return this.http.get(environment.BACKEND_SERVER_URL + '/article/' + label, { params: params, withCredentials: false })
            .pipe(catchError(this.errorHandler));
    };
    ContentService.prototype.updateArticle = function (id, content) {
        return this.http.post(environment.BACKEND_SERVER_URL + '/article/' + id, content, { withCredentials: true })
            .pipe(tap(function (res) {
            if (res && res.warnings && res.warnings.length) {
                alert(res.warnings.map(function (warnings) { return warnings.txt; }).join('\n'));
            }
        }), catchError(this.errorHandler));
    };
    ContentService.prototype.getMyImages = function (params) {
        return this.http.get(environment.BACKEND_SERVER_URL + '/foto/mine', { params: params, withCredentials: true })
            .pipe(catchError(this.errorHandler));
    };
    ContentService.prototype.deleteImage = function (image) {
        return this.http.delete(environment.BACKEND_SERVER_URL + '/foto/' + image.id, { withCredentials: true });
        /* .pipe(
          catchError(this.errorHandler)
        ); */
    };
    ContentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ContentService_Factory() { return new ContentService(i0.ɵɵinject(i1.HttpClient)); }, token: ContentService, providedIn: "root" });
    return ContentService;
}());
export { ContentService };
