import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { environment } from './../../environments/environment';
import { UploadService } from '../upload/upload.service';
import { AuthService } from '../auth.service';
import { ContentService } from '../content.service';
import { MatSnackBar } from '@angular/material';
import { SearchParams } from '../image-list/image-list.component'
import {ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-my-images',
  templateUrl: './my-images.component.html',
  styleUrls: ['./my-images.component.scss']
})
export class MyImagesComponent implements OnInit {
  images;
  noOfImages;
  pageSizeOptions = [10, 50, 100];
  searchParams: SearchParams = {};

  @ViewChild('file', { static: true }) file;
  public files: Set<File> = new Set();
  progress;

  constructor(
    private uploadService: UploadService,
    private contentService: ContentService,
    private authService: AuthService,
    private cdRef: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.searchParams.offset = '0';
    this.searchParams.limit = '50';

    this.activatedRoute.queryParamMap
      .subscribe(queryParams => {
      });

    this.authService.sessionInfo$
      .subscribe(sessionInfo => {
        if (sessionInfo) {
          this.contentService.getMyImages(this.searchParams)
          .subscribe(images => {
            this.images = images.items;
            this.noOfImages = images.total;
          });
        }
      });
  }

  onPageChange(event) {
    this.searchParams.offset = event.pageIndex;
    this.searchParams.limit = event.pageSize;
    this.router.navigate(['/my-images'], {queryParams: this.searchParams});
    this.authService.sessionInfo$
      .subscribe(sessionInfo => {
        if (sessionInfo) {
          this.contentService.getMyImages(this.searchParams)
          .subscribe(images => {
            this.images = images.items;
            this.noOfImages = images.total;
          });
        }
      });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (let key in files) {
      if (!isNaN(parseInt(key))) {
        this.files.add(files[key]);
      }
    }

    // start the upload and save the progress map
    let onComplete$;
    [this.progress, onComplete$] = this.uploadService.upload(
      environment.BACKEND_SERVER_URL + '/foto/' ,
      this.files);
    onComplete$.subscribe(r => {
      this.files.delete(r['file']);
      this.images.unshift(r['response'].foto);
    });

    this.cdRef.detectChanges();
    this.authService.sessionInfo$
      .subscribe(sessionInfo => {
        if (sessionInfo) {
          this.contentService.getMyImages(this.searchParams)
            .subscribe(images => {
              this.images = images.items;
              this.noOfImages = images.total;
            });
        }
      });
  }

  deleteImage(image) {
    this.contentService.deleteImage(image)
      .subscribe(res => {
        this.images.splice(this.images.indexOf(image), 1);
        this.authService.sessionInfo$
          .subscribe(sessionInfo => {
            if (sessionInfo) {
              this.contentService.getMyImages(this.searchParams)
                .subscribe(images => {
                  this.images = images.items;
                  this.noOfImages = images.total;
                });
            }
          });
        this._snackBar.open(image.imageTitle + ' ble slettet', '', { duration: 2000});
      });
  }

}
