<section *ngIf="content" class="ff-single-photo-page-container" fxLayout="column" fxFlex fxLayoutAlign=" center">
    <div class="ff-single-photo-page-content" >
        <div class="ff-single-photo__image-container">
            <img src="{{content.image}}" alt="{{text}}">
        </div>
	<div class="mat-caption" *ngIf="adminuser">Opplastet av: {{content.photographer?.username}} - {{content.photographer?.firstname}} {{content.photographer?.lastname}}</div>
        <div class="ff-single-photo__image-info-container" fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="start" >
            <div class="ff-single-photo__image-info-content" [ngStyle.lt-md]="{'margin-bottom.px': 40}" *ngIf="!((currentUserId === content.photographer?.id) || adminuser)" >
                <div *ngIf="!((currentUserId === content.photographer?.id) || adminuser)">
                    <a mat-flat-button color="primary"
                        href="mailto:elibergefotofil@gmail.com?subject=I am interested in this image {{content.imageTitle}}">Order
                        image</a>
                </div>
                <h1 class="ff-single-photo__image-title">{{content.imageTitle}}</h1>
                <p class="ff-single-photo__image-description">{{content.text}}</p>
                <div class="ff-single-photo__image-categories" fxLayout="row wrap" fxLayoutGap="5px grid" fxLayoutAlign="start center">
                    <div class="ff-single-photo__image-categories-label">Categories:</div>
                    <mat-chip-list aria-label="Image categories" *ngFor="let category of categories">
                        <mat-chip [selectable]="selectable" class="ff-category-chip-read-only"><span (click)="searchCategory(category.id, category.name)" class="truncated-text">{{category.name}}</span></mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div class="ff-single-photo__image-info-content-edit" fxFlex="100" [ngStyle.lt-md]="{'margin-bottom.px': 40}" *ngIf="(currentUserId === content.photographer?.id) || adminuser">
                <h1 class="ff-single-photo__image-title">{{content.imageTitle}}</h1>
                <form [formGroup]="contentForm" (ngSubmit)="onSubmit()">
                    <!-- <mat-form-field appearance="outline">
                        <mat-label>Overskrift</mat-label>
                        <input matInput placeholder="Skriv en tittel" formControlName="imageTitle">
                    </mat-form-field> -->
                    <mat-form-field appearance="outline">
                        <mat-label>Tekst</mat-label>
                        <textarea matInput cdkTextareaAutosize placeholder="Skriv en tekst" formControlName="text"></textarea>
                    </mat-form-field>
                    <mat-error *ngIf="contentForm.get('text').invalid">
                        Tekstfeltet kan ikke være tomt, husk å legge til fotograf her
                    </mat-error>
                    <br>
                    <mat-form-field appearance="outline">
                        <mat-label>Kategorier</mat-label>
                        <mat-chip-list #chipList aria-label="Choose category">
                            <mat-chip *ngFor="let category of categories" [selectable]="selectable"
                                (removed)="remove(category)">
                                {{category?.name}}
                                <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input placeholder="Add category..." #categoryInput [formControl]="categoryCtrl" [matAutocomplete]="auto"
                                [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">    
                        </mat-chip-list>
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                            <!-- <mat-option *ngFor="let category of filteredCategories | async" [value]="category">
                                {{category.label}}
                            </mat-option> -->
                            <mat-optgroup *ngFor="let category of filteredCategories | async" [label]="category.label">
                                <mat-option *ngFor="let subcategory of category.children" [value]="subcategory">
                                    {{subcategory.label}}
                                </mat-option>
                            </mat-optgroup>
                        </mat-autocomplete>
                    </mat-form-field>

                    <br>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div formGroupName="archiveGroup">
                            <mat-checkbox formControlName="is_photo">Photography</mat-checkbox> &nbsp;
                            <mat-checkbox formControlName="is_art">Art</mat-checkbox> &nbsp;
                            <mat-checkbox formControlName="is_illustration">Illustration</mat-checkbox>
                            <br>
                            <mat-error *ngIf="contentForm.get('archiveGroup').invalid">
                                Husk minst et arkiv!
                            </mat-error>
                        </div>

                        <button style="margin-left: 24px;" type="submit" [disabled]="!contentForm.valid || !contentForm.dirty" mat-button mat-raised-button
                            color="primary">Lagre</button>
                    </div>
                </form>
                <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 40px;">
                    <button mat-stroked-button color="warn" (click)="deleteImage(content)">
                        <mat-icon>delete</mat-icon> Slett bilde
                    </button>
                </div>
                <!-- <br><br><br>{{contentForm.value | json}} -->
            </div>
        </div>
        <!-- <div *ngIf="!userIsLoggedIn" class="ff-single-photo__navigation" fxLayout="row" fxLayoutAlign="space-between center">
            <button mat-button color="primary">
                <mat-icon>chevron_left</mat-icon>
                Previous
            </button>
            <button mat-button color="primary">
                Next
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div> -->
        <!-- <div *ngIf="userIsLoggedIn" div fxLayout="row" fxLayoutAlign="end center">
            <button mat-raised-button color="primary">Lagre</button>
        </div> -->
    </div>
</section>

