<div class="ff-search">
  <form [formGroup]="searchForm" *ngIf="searchForm" (ngSubmit)="submitSearch()">
    <div>
      <div>
        <mat-checkbox formControlName="is_photo">Photography</mat-checkbox>
      </div>
      <div>
        <mat-checkbox formControlName="is_art">Art</mat-checkbox>
      </div>
      <div>
        <mat-checkbox formControlName="is_illustration">Illustration</mat-checkbox>
      </div>
    </div>

    <mat-form-field appearance="outline">
      <mat-label>Søk/Keywords</mat-label>
      <input matInput placeholder="Søk/keywords" formControlName="searchString1">
      <mat-hint>Norsk/English</mat-hint>
    </mat-form-field>

    
    <mat-radio-group formControlName="operation" fxLayout="row" fxLayoutAlign="start center">
      <mat-radio-button value="and"> And </mat-radio-button>
      <!-- <mat-radio-button value="or"> Or </mat-radio-button> -->
      <mat-radio-button value="not"> Not </mat-radio-button>
    </mat-radio-group>
    
    <mat-form-field appearance="outline">
      <mat-label>Søk/Keywords</mat-label>
      <input matInput placeholder="Søk/keywords" formControlName="searchString2">
      <mat-hint>Norsk/English</mat-hint>
    </mat-form-field>

    
    <mat-chip-list *ngIf="searchForm.get('category_id').value">
        <mat-chip>
            {{searchForm.get('category_label').value}}
            <mat-icon matChipRemove (click)="removeCategory()">cancel</mat-icon>
          </mat-chip>
    </mat-chip-list><br>

    <input type="hidden" formControlName="category_id">
    <input type="hidden" formControlName="category_label">

    <button mat-stroked-button color="primary" type="submit">Søk/Search</button>

  </form>
  <mat-divider></mat-divider>
</div>




<app-categories (categorySelected)="onCategorySelected($event)"></app-categories>