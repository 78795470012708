import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ImageListService } from '../image-list.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  @Output() triggerCloseNav = new EventEmitter<string>();
  public searchForm: FormGroup;

  constructor(
    private imageListService: ImageListService,
    private router: Router
  ) { }
  
  ngOnInit() {
    this.searchForm = new FormGroup({
      is_photo: new FormControl(true),
      is_art: new FormControl(true),
      is_illustration: new FormControl(true),
      searchString1: new FormControl(''),
      operation: new FormControl('and'),
      searchString2: new FormControl(''),
      category_id: new FormControl(''),
      category_label: new FormControl('')
    });
  }

  public removeCategory() {
    this.searchForm.controls.category_id.setValue('');
    this.searchForm.controls.category_label.setValue('');
  }

  public submitSearch() {
    const params = this.searchForm.value;
    params.offset = 0;
    this.router.navigate(['/search'], {queryParams: params});
    this.triggerCloseNav.next('close');
  }

  onCategorySelected(catData) {
    this.searchForm.controls.category_id.setValue(catData.category_id);
    this.searchForm.controls.category_label.setValue(catData.category_label);
    this.submitSearch();
  }

  // doSeach() {
  //   const params = this.searchForm.value;
  //   params.offset = 0;
  //   this.router.navigate(['/search'], {queryParams: params});
  //   this.triggerCloseNav.next('close');
  // }

}
