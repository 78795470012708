import { OnInit, EventEmitter } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
var CategoriesComponent = /** @class */ (function () {
    function CategoriesComponent(categoriesService, authService, imageListService, fb, _snackBar, router) {
        this.categoriesService = categoriesService;
        this.authService = authService;
        this.imageListService = imageListService;
        this.fb = fb;
        this._snackBar = _snackBar;
        this.router = router;
        this.categorySelected = new EventEmitter();
        this.treeControl = new NestedTreeControl(function (node) { return node.children; });
        this.dataSource = new MatTreeNestedDataSource();
        this.editMode = false;
        this.ds = new MatTreeNestedDataSource(); // TEST
        this.hasChild = function (_, node) { return !!node.children && node.children.length > 0; };
    }
    CategoriesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.categoriesService.getCategories()
            .subscribe(function (categories) {
            _this.dataSource.data = categories;
            _this.contentForm = _this.fb.group({
                categories: _this.fb.array(categories.map(function (cat) { return _this.fb.group({
                    label: cat.label,
                    children: _this.fb.array(cat.children.map(function (subcat) { return _this.fb.group({
                        label: subcat.label
                    }); }))
                }); }))
            });
            _this.ds = _this.contentForm.value;
        });
        // this.tableForm = this.fb.group({
        //   rows: this.fb.array(
        //     initialTable.rows.map(row => this.fb.group({
        //           cells: this.fb.array(
        //             row.cells.map(cell => this.fb.group(cell))
        //           )
        //         })))
        // });
    };
    Object.defineProperty(CategoriesComponent.prototype, "mainCats", {
        get: function () {
            return this.contentForm.get('categories');
        },
        enumerable: true,
        configurable: true
    });
    CategoriesComponent.prototype.searchCategory = function (id, label) {
        this.categorySelected.next({ 'category_id': id, 'category_label': label });
        // this.router.navigate(['/search'], {queryParams: {'category_id': id, 'category_label': label, 'offset': 0}});
    };
    CategoriesComponent.prototype.editLabel = function (category) {
        this.categoriesService.updateCategory(category)
            .subscribe(function (res) {
        });
    };
    CategoriesComponent.prototype.addSubCategory = function (category, treeControl) {
        var _this = this;
        if (!treeControl.isExpanded(category)) {
            treeControl.expand(category);
        }
        var idx = this.dataSource.data.findIndex(function (cat) {
            return cat.id === category.id;
        });
        if (this.dataSource.data[idx]) {
            var newCat_1 = { id: null, label: "NY!", parent: category.id };
            this.categoriesService.addCategory(newCat_1)
                .subscribe(function (res) {
                newCat_1.id = res.category.id;
                _this.dataSource.data[idx].children.unshift(newCat_1);
                _this.refreshTree();
                _this.openSnackBar('La til kategorien ' + newCat_1.label, '');
            });
        }
    };
    CategoriesComponent.prototype.addMainCategory = function () {
        var _this = this;
        var newCat = { id: null, label: "NY!", parent: null, children: [] };
        this.categoriesService.addCategory(newCat)
            .subscribe(function (res) {
            newCat.id = res.category.id;
            _this.dataSource.data.unshift(newCat);
            _this.refreshTree();
            _this.openSnackBar('La til kategorien ' + newCat.label, '');
        });
        this.refreshTree();
    };
    CategoriesComponent.prototype.removeCategory = function (category) {
        var _this = this;
        if (category.parent === null) {
            if (category.children && category.children.length > 0) {
                this.openSnackBar('Kan ikke slette en kategori som har underkategorier', '');
            }
            else {
                // Slette tom hovedkategori
                var idx = this.dataSource.data.findIndex(function (cat) {
                    return cat.id === category.id;
                });
                this.dataSource.data.splice(idx, 1);
                this.categoriesService.deleteCategory(category)
                    .subscribe(function (res) {
                    _this.openSnackBar('Slettet kategorien ' + category.label, '');
                });
            }
        }
        else {
            // Slette underkategori
            var parentIdx = this.dataSource.data.findIndex(function (cat) {
                return cat.id === category.parent;
            });
            if (this.dataSource.data[parentIdx]) {
                var idx = this.dataSource.data[parentIdx].children.findIndex(function (cat) {
                    return cat.id === category.id;
                });
                this.dataSource.data[parentIdx].children.splice(idx, 1);
                this.categoriesService.deleteCategory(category)
                    .subscribe(function (res) {
                    _this.openSnackBar('Slettet kategorien ' + category.label, '');
                });
            }
        }
        this.refreshTree();
    };
    CategoriesComponent.prototype.refreshTree = function () {
        // Hack
        var data = this.dataSource.data;
        this.dataSource.data = null;
        this.dataSource.data = data;
    };
    CategoriesComponent.prototype.openSnackBar = function (message, action) {
        this._snackBar.open(message, action, {
            duration: 2000,
        });
    };
    CategoriesComponent.prototype.isFirstLevel = function (_, nodeData) {
        return nodeData.parent ? false : true;
    };
    return CategoriesComponent;
}());
export { CategoriesComponent };
