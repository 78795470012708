import { environment } from '../environments/environment';
import { throwError } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./content.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/material/snack-bar";
var AuthService = /** @class */ (function () {
    function AuthService(http, contentService, router, _snackBar) {
        this.http = http;
        this.contentService = contentService;
        this.router = router;
        this._snackBar = _snackBar;
        this.sessionInfo$ = new BehaviorSubject(null);
    }
    AuthService.prototype.intercept = function (req, next) {
        var _this = this;
        return next.handle(req)
            .pipe(catchError(function (err) {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    // this.sessionInfo = null;
                    _this.sessionInfo$.next(null);
                    _this.router.navigate(['']);
                    _this._snackBar.open('Bruker er ikke innlogget med nødvendige rettigheter', 'lukk');
                }
            }
            return throwError(err);
        }));
    };
    AuthService.prototype.errorHandler = function (err) {
        if (err.error && err.error.message) {
            alert(err.error.message);
        }
        else if (err.error && err.error._issues) {
            alert(Object.entries(err.error._issues).map(function (entry) {
                return entry[0] + ' : ' + entry[1];
            }).join('\n'));
        }
        else {
            alert('Feil på url: ' + err.url + '\n Status code: ' + err.status + '\n' + err.message);
        }
        return throwError(err);
    };
    AuthService.prototype.fetchSessionInfoOnInit = function () {
        var _this = this;
        return this.http.get(environment.BACKEND_SERVER_URL + '/sessioninfo', { withCredentials: true })
            .pipe(catchError(this.errorHandler))
            .subscribe(function (sessionInfo) {
            _this.sessionInfo$.next(sessionInfo);
        });
    };
    AuthService.prototype.login = function (userInfo) {
        var _this = this;
        var content = {
            username: userInfo.username,
            password: userInfo.password
        };
        var httpOptions = {
            withCredentials: true
        };
        return this.http.post(environment.BACKEND_SERVER_URL + '/login', content, httpOptions)
            .pipe(tap(function (sessionInfo) {
            _this.sessionInfo$.next(sessionInfo);
        }));
    };
    AuthService.prototype.resetPassword = function (username) {
        var content = {
            username: username
        };
        var httpOptions = {
            withCredentials: true
        };
        return this.http.post(environment.BACKEND_SERVER_URL + '/iforgot', content, httpOptions)
            .pipe(catchError(this.errorHandler));
    };
    AuthService.prototype.setPassword = function (token, password) {
        var content = {
            token: token,
            password: password,
        };
        var httpOptions = {
            withCredentials: true
        };
        return this.http.post(environment.BACKEND_SERVER_URL + '/resetpassword', content, httpOptions)
            .pipe(catchError(this.errorHandler));
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        return this.http.get(environment.BACKEND_SERVER_URL + '/logout', { withCredentials: true })
            .pipe(catchError(this.errorHandler))
            .subscribe(function (res) {
            _this.sessionInfo$.next(null);
            _this.router.navigate(['/']);
        }, function (err) {
            console.error('Could not logout', err);
        });
    };
    AuthService.prototype.authenticate = function (uname, pwd) {
        return this.login({ username: uname, password: pwd });
    };
    AuthService.prototype.getUserInfo = function () {
        return this.sessionInfo$
            .pipe(map(function (sessionInfo) {
            return sessionInfo ? sessionInfo.user : null;
        }));
    };
    AuthService.prototype.isAuthenticated = function () {
        return this.sessionInfo$
            .pipe(map(function (sessionInfo) {
            return sessionInfo && sessionInfo.logged_in;
        }));
    };
    AuthService.prototype.isAdminUser = function () {
        return this.sessionInfo$
            .pipe(map(function (sessionInfo) {
            if (sessionInfo && sessionInfo.user && sessionInfo.user.adminuser) {
                return true;
            }
            else {
                return false;
            }
        }));
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ContentService), i0.ɵɵinject(i3.Router), i0.ɵɵinject(i4.MatSnackBar)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
