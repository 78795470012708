<div *ngIf="masonryImages" class="ff-image-list-page-container" fxLayout="column" fxFlex fxLayoutAlign=" center">
    <div class="ff-paginator">
        <mat-paginator 
            [length]="noOfImages"
            [pageIndex]="searchParams.offset"
            [pageSize]="searchParams.limit"
            [pageSizeOptions]="pageSizeOptions"
            (page)="onPageChange($event)" [showFirstLastButtons]="true"
        ></mat-paginator>
    </div>
    <div *ngIf="searchParams.category_label" class="mat-title">{{searchParams.category_label}}</div>
    <div class="ff-image-list-page-content">
        <mdc-image-list #demomasonry [masonry]="true" class="masonry-image-list">
            <mdc-image-list-item *ngFor="let item of masonryImages">
                <a [routerLink]="['/single-photo/' +item.id]">
                    <img mdcImageListImage src="{{item.image}}" />
                    <img class="hover-image" mdcImageListImage src="{{item.image}}" />
                </a>
                <mdc-image-list-supporting>
                    <span mdcImageListLabel>{{item.imageTitle}}</span>
                </mdc-image-list-supporting>
            </mdc-image-list-item>
        </mdc-image-list>
    </div>
    <div class="ff-paginator">
        <mat-paginator [length]="noOfImages" [pageIndex]="searchParams.offset" [pageSize]="searchParams.limit"
            [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)" [showFirstLastButtons]="true"></mat-paginator>
    </div>
</div>
<div *ngIf="!masonryImages" fxFlex fxLayout="column" fxLayoutAlign=" center">
<mat-spinner [diameter]="40"></mat-spinner>
</div>
