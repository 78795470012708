import { Component, OnInit } from '@angular/core';
import { ImageListService, ImageListData } from './../image-list.service';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';

export interface SearchParams {
  category_id?: string,
  category_label?: string,
  is_photo?: string,
  is_art?: string,
  is_illustration?: string,
  searchString1?: string,
  operation?: string,
  searchString2?: string,
  offset?: string,
  limit?: string
}
@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit {

  images = Array.from(Array(15), (x, i) => i);
  masonryImages: ImageListData[] = [];
  pagingOptions;
  noOfImages;
  pageSizeOptions = [10, 50, 100];
  searchParams: SearchParams = {};

  constructor(
    private imageListService: ImageListService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.searchParams.offset = '0';
    this.searchParams.limit = '50';

    this.activatedRoute.queryParamMap
      .subscribe(queryParams => {
        this.getImages(queryParams);
      });
  }

  getImages(queryParams) {
    this.updateSearchParams(queryParams);

    if (queryParams.keys.length === 0) {
      this.imageListService.getAllPhotoes(this.searchParams)
        .subscribe(bilder => {
          this.masonryImages = bilder.items;
          this.noOfImages = bilder.total;
        })

    // } else if (queryParams.get('category_id')) {
    //   this.imageListService.getImagesByCategory(queryParams.get('category_id'), this.searchParams)
    //     .subscribe(bilder => {
    //       this.masonryImages = bilder.items;
    //       this.noOfImages = bilder.total;
    //     })

    } else {
      this.imageListService.getImagesBySearchParams(this.searchParams)
        .subscribe(bilder => {
          this.masonryImages = bilder.items;
          this.noOfImages = bilder.total;
        })
    }
  }

  onPageChange(event) {
    this.searchParams.offset = event.pageIndex;
    this.searchParams.limit = event.pageSize;
    this.router.navigate(['/search'], {queryParams: this.searchParams});
  }

  updateSearchParams(queryParams) {
    const searchParams: SearchParams = {};
    if (queryParams.get('category_id')) {
      searchParams.category_id = queryParams.get('category_id');
    }
    if (queryParams.get('category_label')) {
      searchParams.category_label = queryParams.get('category_label');
    }
    if (queryParams.get('is_photo')) {
      searchParams.is_photo = queryParams.get('is_photo');
    }
    if (queryParams.get('is_art')) {
      searchParams.is_art = queryParams.get('is_art');
    }
    if (queryParams.get('is_illustration')) {
      searchParams.is_illustration = queryParams.get('is_illustration');
    }
    if (queryParams.get('searchString1')) {
      searchParams.searchString1 = queryParams.get('searchString1');
    }
    if (queryParams.get('operation')) {
      searchParams.operation = queryParams.get('operation');
    }
    if (queryParams.get('searchString2')) {
      searchParams.searchString2 = queryParams.get('searchString2');
    }
    if (queryParams.get('offset')) {
      searchParams.offset = queryParams.get('offset');
    } else {
      searchParams.offset = "0";
    }
    if (queryParams.get('limit')) {
      searchParams.limit = queryParams.get('limit');
    } else {
      searchParams.limit = "50";
    }

    this.searchParams = searchParams;
  }

}
