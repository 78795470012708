
<mat-slide-toggle color="primary" *ngIf="authService.isAdminUser() | async" class="ff-btn-typo" [(ngModel)]="editMode">Rediger</mat-slide-toggle>

<div *ngIf="editMode" style="display: flex; justify-content: center; padding-top: 20px; margin-bottom: 20px;">
    <button mat-button mat-raised-button color="primary" (click)="addMainCategory()">
      <mat-icon>add</mat-icon>
      Ny hovedkategori
    </button>
</div>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let category; when: !isFirstLevel" matTreeNodeToggle>
      <li class="mat-tree-node">
        <!-- use a disabled button to provide padding for tree leaf -->
        <button mat-icon-button disabled></button>
        <a *ngIf="editMode">
          <input (change)="editLabel(category)" type="text" [(ngModel)]="category.label">
          <button mat-icon-button class="remove-button" (click)="removeCategory(category)">
              <mat-icon>remove_circle_outline</mat-icon>
          </button>
        </a>
        <a *ngIf="!editMode" (click)="searchCategory(category.id, category.label)">{{category.label}}</a>
      </li>
    </mat-tree-node>
    
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let category; when: isFirstLevel">
      <li>
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle
                  [attr.aria-label]="'toggle ' + category.label">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(category) ? 'arrow_drop_up' : 'arrow_drop_down'}}
            </mat-icon>
          </button>
          <a *ngIf="editMode">
            <input (change)="editLabel(category)" type="text" [(ngModel)]="category.label">
            <button mat-icon-button class="remove-button" (click)="removeCategory(category)">
                <mat-icon>remove_circle_outline</mat-icon>
            </button>
            <button mat-icon-button class="add-button" (click)="addSubCategory(category, treeControl)" >
                <mat-icon>add_circle_outline</mat-icon>
            </button>
          </a>
          <a *ngIf="!editMode" (click)="searchCategory(category.id, category.label)" matTreeNodeToggle>{{category.label}}</a>
        </div>
        <ul [class.example-tree-invisible]="!treeControl.isExpanded(category)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
