import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';


// import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(private http: HttpClient) { }


  public getSearchResult(url: string, field, query, modifier?) {
    let filter;
    if (modifier) {
      filter = `{"${field}":"ilike(\\"%${query}%\\")",${modifier}}`;
    } else {
      filter = `{"${field}":"ilike(\\"%${query}%\\")"}`;
    }

    const httpOptions = {
      withCredentials: true,
      params: { 'where': filter }
    };

    return this.http.get<any>(environment.BACKEND_SERVER_URL + url, httpOptions);

  }

  private errorHandler(err) {
    if (err.error && err.error.message) {
      alert(err.error.message);
    } else if (err.error && err.error._issues) {
      alert(
        Object.entries(err.error._issues).map(entry => {
          return entry[0] + ' : ' + entry[1];
        }).join('\n')
      );
    } else {
      alert('Feil på url: ' + err.url + '\n Status code: ' + err.status + '\n' + err.message);
    }
    return throwError(err);
  }

  public fetchSisteBilder() {
    const params: any = {};
    return this.http.get<any>(environment.BACKEND_SERVER_URL + '/foto', { params: params, withCredentials: false })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public getImageDetails(photoid: number) {
    const params: any = {};
    return this.http.get<any>(environment.BACKEND_SERVER_URL + '/foto/' + photoid, { params: params, withCredentials: false })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public saveImageDetails(photo) {
    return this.http.post<any>(environment.BACKEND_SERVER_URL + '/foto/' + photo.id, photo, { withCredentials: true })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public fetchContentWithQueryParams(url, data) {
    return this.http.get<any>(environment.BACKEND_SERVER_URL + url, { params: data, withCredentials: true })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public fetchContent(url: string) {
    // const backend   =environment.backendServerUrl ? environment.backendServerUrl : '';
    return this.http.get<any>(environment.BACKEND_SERVER_URL + url, { withCredentials: true })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public postContent(url: string, content) {
    return this.http.post(environment.BACKEND_SERVER_URL + url, content, { withCredentials: true })
      .pipe(
        tap((res: any) => {
          if (res && res.warnings && res.warnings.length) {
            alert(res.warnings.map(warnings => warnings.txt).join('\n'));
          }
        }),
        catchError(this.errorHandler)
      );
  }

  public putContent(url: string, content) {
    return this.http.put(environment.BACKEND_SERVER_URL + url, content, { withCredentials: true })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public deleteContent(url: string) {
    return this.http.delete(environment.BACKEND_SERVER_URL + url, { withCredentials: true })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public updateContent(url: string, content, eTag: string) {
    let httpOptions;
    if (eTag) {
      httpOptions = {
        withCredentials: true,
        headers: new HttpHeaders({
          'If-Match': eTag
          // ,
          // 'Content-type': 'application/x-www-form -urlencoded; charset= UTF-8'
          // 'Content-Type': // 'application/json',
          // 'application/x-www-form-urlencoded; charset=UTF-8'
        })
      };
    } else {
      httpOptions = { withCredentials: true };
    }
    return this.http.patch(environment.BACKEND_SERVER_URL + url, content, httpOptions)
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public search(params) {
    return this.http.get<any>(environment.BACKEND_SERVER_URL + '/foto/search', { params: params, withCredentials: false })
    .pipe(
      catchError(this.errorHandler)
    );
  }

  public getArticle(label) {
    const params: any = {};
    return this.http.get<any>(environment.BACKEND_SERVER_URL + '/article/' + label, { params: params, withCredentials: false })
      .pipe(
        catchError(this.errorHandler)
      );
  }

  public updateArticle(id: string, content) {
    return this.http.post(environment.BACKEND_SERVER_URL + '/article/' + id, content, { withCredentials: true })
      .pipe(
        tap((res: any) => {
          if (res && res.warnings && res.warnings.length) {
            alert(res.warnings.map(warnings => warnings.txt).join('\n'));
          }
        }),
        catchError(this.errorHandler)
      );
  }

  public getMyImages(params) {
    return this.http.get<any>(environment.BACKEND_SERVER_URL + '/foto/mine', {  params: params, withCredentials: true })
    .pipe(
      catchError(this.errorHandler)
    );
  }

  public deleteImage(image) {
    return this.http.delete(environment.BACKEND_SERVER_URL + '/foto/' + image.id, { withCredentials: true })
    /* .pipe(
      catchError(this.errorHandler)
    ); */
  }
}
