<!--The content below is only a placeholder and can be replaced.-->
<!-- <div style="text-align:center">
  <h1>
    Welcome to {{ title }}!
  </h1>
  <button mat-button>test</button>
  <img width="300" alt="Angular Logo" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICAgIDxwYXRoIGZpbGw9IiNERDAwMzEiIGQ9Ik0xMjUgMzBMMzEuOSA2My4ybDE0LjIgMTIzLjFMMTI1IDIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMXoiIC8+CiAgICA8cGF0aCBmaWxsPSIjQzMwMDJGIiBkPSJNMTI1IDMwdjIyLjItLjFWMjMwbDc4LjktNDMuNyAxNC4yLTEyMy4xTDEyNSAzMHoiIC8+CiAgICA8cGF0aCAgZmlsbD0iI0ZGRkZGRiIgZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiAvPgogIDwvc3ZnPg==">
</div>
<h2>Here are some links to help you start: </h2>
<ul>
  <li>
    <h2><a target="_blank" rel="noopener" href="https://angular.io/tutorial">Tour of Heroes</a></h2>
  </li>
  <li>
    <h2><a target="_blank" rel="noopener" href="https://angular.io/cli">CLI Documentation</a></h2>
  </li>
  <li>
    <h2><a target="_blank" rel="noopener" href="https://blog.angular.io/">Angular blog</a></h2>
  </li>
</ul> -->



<div style="height: 100vh; height: calc(100vh - 64px);">
  <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center" >
    <div fxShow="true">
      <button (click)="sidenav.toggle()" mat-icon-button aria-label="show menu">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
    <a class="home-link" href="#" [routerLink]="['/']">
      <span>Fotofil</span>
    </a>
    <div fxFlex fxLayout="row" fxLayoutAlign="space-between center" fxShow="true" fxHide.lt-md="true" class="toolbar-links-md">
      <!-- The following menu items will be hidden on both SM and XS screen sizes -->
      <div style="margin-left: 170px;">
        <a *ngIf="auth.isAdminUser() | async" href="#" [routerLink]="['/users']" routerLinkActive="current-page" mat-button>Redigere Brukere</a>
        <a *ngIf="auth.isAuthenticated() | async" href="#" [routerLink]="['/my-images']" routerLinkActive="current-page" mat-button>Mine bilder</a>
        <!-- <mat-slide-toggle class="ff-btn-typo" color="secondary">Redigere kategorier</mat-slide-toggle> -->
      </div>
      <div>
        <a href="#" [routerLink]="['/article/members']" routerLinkActive="current-page" mat-button>Members</a>
        <a href="#" [routerLink]="['/article/join']" routerLinkActive="current-page" mat-button>Join Us</a>
        <a href="#" [routerLink]="['/article/customers']" routerLinkActive="current-page" mat-button>References</a>
        <a href="#" [routerLink]="['/article/contact']" routerLinkActive="current-page" mat-button>Contact</a>
        <button *ngIf="!(auth.isAuthenticated() | async)" (click)="openLoginDialog()" mat-button>Log in</button>
        <button *ngIf="auth.isAuthenticated() | async" (click)="logout()" mat-button>Log out</button>
      </div>
    </div>

    <div fxShow="true" fxHide.gt-sm="true">
      <button mat-button mat-icon-button [matMenuTriggerFor]="sitemenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #sitemenu="matMenu">
        <a *ngIf="auth.isAdminUser() | async" href="#" [routerLink]="['/users']" routerLinkActive="current-page" mat-menu-item>Redigere Brukere</a>
        <!-- <a href="#" mat-menu-item>Redigere kategorier</a> -->
        <a *ngIf="auth.isAuthenticated() | async" href="#" [routerLink]="['/my-images']" routerLinkActive="current-page" mat-menu-item>Mine bilder</a>
        <a href="#" mat-menu-item [routerLink]="['/article/members']" routerLinkActive="current-page">Members</a>
        <a href="#" mat-menu-item [routerLink]="['/article/join']" routerLinkActive="current-page">Join Us</a>
        <a href="#" mat-menu-item [routerLink]="['/article/customers']" routerLinkActive="current-page">References</a>
        <a href="#" mat-menu-item [routerLink]="['/article/contact']" routerLinkActive="current-page">Contact</a>
        <mat-divider></mat-divider>
        <button *ngIf="!(auth.isAuthenticated() | async)" (click)="openLoginDialog()" mat-menu-item>Log in</button>
        <button *ngIf="auth.isAuthenticated() | async" (click)="logout()" mat-menu-item>Log out</button>
      </mat-menu>


    </div>
  </mat-toolbar>

  <mat-sidenav-container fxFlexFill>
    <mat-sidenav #sidenav [opened]="opened" [mode]="mode" fxLayout="column">
      <div class="ff-sidenav-container" fxLayout="column">
        <ng-container *ngIf="auth.getUserInfo() | async as userinfo">
          <div class="ff-user-info">
            <div class="mat-small">Logget inn som</div>
	          <div class="mat-small">{{userinfo.firstname}} {{userinfo.lastname}}</div>
          </div>
          <div class="ff-user-info-divider">
            <mat-divider></mat-divider>
          </div>
        </ng-container>
        <app-search (triggerCloseNav)="triggerCloseNav()"></app-search>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <main class="ff-page-container" fxLayout="column">
        <!-- <app-user-banner></app-user-banner> -->
        <router-outlet></router-outlet>
      </main>
      <footer>
        <div class="ff-footer" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
          Copyright ©&nbsp;<a href="/">Fotofil.no</a>&nbsp;Eli Berge 2002 Oslo Norway +47 450 32 302&nbsp;<a
          href="mailto:elibergefotofil@gmail.com">elibergefotofil@gmail.com</a>
        </div>
        <div class="rw-branding" fxLayout="row" fxLayoutAlign="center">
          Teknisk løsning er laget av&nbsp;<a href="http://ravn.no" target="_blank">Ravn Webveveriet AS</a>
        </div>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
