import { OnInit, ElementRef } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { IconSnackBarComponent } from '../icon-snack-bar/icon-snack-bar.component';
import { map, startWith } from 'rxjs/operators';
var SinglePhotoComponent = /** @class */ (function () {
    function SinglePhotoComponent(contentService, categoriesService, activatedRoute, router, authService, fb, _snackBar, meta) {
        this.contentService = contentService;
        this.categoriesService = categoriesService;
        this.activatedRoute = activatedRoute;
        this.router = router;
        this.authService = authService;
        this.fb = fb;
        this._snackBar = _snackBar;
        this.meta = meta;
        // visible = true;
        // selectable = true;
        // removable = true;
        this.addOnBlur = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.categoryCtrl = new FormControl();
        this.editable = false;
        this.currentUserId = null;
        this.adminuser = false;
        this._filter = function (opt, value) {
            var filterValue = value.toLowerCase();
            return opt.filter(function (item) { return item.label.toLowerCase().indexOf(filterValue) === 0; });
        };
    }
    SinglePhotoComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.authService.sessionInfo$
            .subscribe(function (sessionInfo) {
            if (sessionInfo && sessionInfo.user) {
                if (sessionInfo.user.id) {
                    _this.currentUserId = sessionInfo.user.id;
                }
                else {
                    _this.currentUserId = null;
                }
                _this.adminuser = sessionInfo.user.adminuser;
            }
        });
        this.categoriesService.getCategories()
            .subscribe(function (categories) {
            _this.allCategories = categories;
            _this.filteredCategories = _this.categoryCtrl.valueChanges.pipe(startWith(null), map(function (value) { return _this._filterGroup(value); }));
        });
        this.sub = this.activatedRoute.params.subscribe(function (params) {
            var id = +params['id'];
            _this.contentService.getImageDetails(id).subscribe(function (photo) {
                _this.content = photo;
                _this.contentForm = _this.fb.group({
                    id: photo.id,
                    text: [photo.text, Validators.required],
                    imageTitle: photo.imageTitle,
                    archiveGroup: _this.fb.group({
                        is_photo: photo.is_photo,
                        is_art: photo.is_art,
                        is_illustration: photo.is_illustration
                    }, { validator: _this.oneIsChecked }),
                    categories: _this.fb.array(photo.categories)
                });
                _this.categories = photo.categories;
                var categoriesString = '';
                _this.categories.map(function (category) {
                    categoriesString += category.name + ', ';
                });
                _this.meta.addTag({ name: 'keywords', content: photo.text + " " + categoriesString });
            });
        });
    };
    SinglePhotoComponent.prototype.oneIsChecked = function (group) {
        return Object.keys(group.controls).some(function (x) { return group.controls[x].value === true; }) ?
            null : { notValid: true };
    };
    Object.defineProperty(SinglePhotoComponent.prototype, "categoriesFormArray", {
        get: function () {
            return this.contentForm.get('categories');
        },
        enumerable: true,
        configurable: true
    });
    SinglePhotoComponent.prototype.ngOnDestroy = function () {
        this.sub.unsubscribe();
    };
    SinglePhotoComponent.prototype.add = function (event) {
        if (!this.matAutocomplete.isOpen) {
            var input = event.input;
            var value = event.value;
            if ((value || '')) {
                this.categories.push(value);
            }
            if (input) {
                input.value = '';
            }
            this.categoryCtrl.setValue(null);
        }
    };
    SinglePhotoComponent.prototype.remove = function (category) {
        var index = this.categories.indexOf(category);
        if (index >= 0) {
            this.categories.splice(index, 1);
            this.categoriesFormArray.removeAt(index);
            this.contentForm.markAsDirty();
        }
    };
    SinglePhotoComponent.prototype.selected = function (event) {
        var cat = { name: event.option.value.label, id: event.option.value.id };
        this.categories.push(cat);
        this.categoryInput.nativeElement.value = '';
        this.categoryCtrl.setValue(null);
        this.categoriesFormArray.push(this.fb.control(cat));
        this.contentForm.markAsDirty();
    };
    SinglePhotoComponent.prototype._filterGroup = function (value) {
        var _this = this;
        if (value && typeof value === 'string') {
            return this.allCategories
                .map(function (category) { return ({ label: category.label, id: category.id, children: _this._filter(category.children, value) }); })
                .filter(function (category) { return category.children.length > 0; });
        }
        return this.allCategories;
    };
    SinglePhotoComponent.prototype.onSubmit = function () {
        var _this = this;
        var imageDetails = {
            is_art: this.contentForm.value.archiveGroup.is_art,
            is_illustration: this.contentForm.value.archiveGroup.is_illustration,
            is_photo: this.contentForm.value.archiveGroup.is_photo,
            categories: this.contentForm.value.categories,
            id: this.contentForm.value.id,
            imageTitle: this.contentForm.value.imageTitle,
            text: this.contentForm.value.text
        };
        this.contentService.saveImageDetails(imageDetails)
            .subscribe(function (ret) {
            //this._snackBar.open('Endringer er lagret', '', {duration: 2000});
            _this._snackBar.openFromComponent(IconSnackBarComponent, {
                data: { message: 'Endringer er lagret!', icon: 'check_circle' },
                duration: 2000
            });
            _this.contentForm.markAsPristine();
        });
    };
    SinglePhotoComponent.prototype.searchCategory = function (id, label) {
        this.router.navigate(['/search'], { queryParams: { 'category_id': id, 'category_label': label, 'offset': 0 } });
    };
    SinglePhotoComponent.prototype.deleteImage = function (content) {
        var _this = this;
        this.contentService.deleteImage(content)
            .subscribe(function (res) {
            _this._snackBar.open(content.imageTitle + ' ble slettet', '', { duration: 2000 });
            _this.router.navigate(['/']);
        });
    };
    return SinglePhotoComponent;
}());
export { SinglePhotoComponent };
