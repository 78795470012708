
<h2 mat-dialog-title>Login to fotofil.no</h2>
<form [formGroup]="loginForm" (ngSubmit)="submit()">
    <mat-dialog-content class="mat-typography">
        <div>
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>Username</mat-label>
                <input matInput placeholder="Type your username" formControlName="username">
            </mat-form-field>
        </div>
        <div>
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>Password</mat-label>
                <input matInput placeholder="Type your password" type="password" formControlName="password">
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button type="button" (click)="close()">Avbryt</button>
        <button mat-raised-button [disabled]="!loginForm.valid" type="submit" cdkFocusInitial color="primary">Logg inn</button>
    </mat-dialog-actions>
</form>
