import { Injectable } from '@angular/core';
import { ContentService } from './content.service';

export interface ImageListData {
  image: String,
  imageTitle: String
}


@Injectable({
  providedIn: 'root'
})
export class ImageListService {
  public pagingOptions;

  constructor(private contentService: ContentService) {
  }

  public getImagesByCategory(categoryId, searchParams) {
    const queryUrl = '/foto/category/' + categoryId;
    return this.contentService.fetchContentWithQueryParams(queryUrl, searchParams)
  }

  public getAllPhotoes(searchParams) {
    const queryUrl = '/foto';
    return this.contentService.fetchContentWithQueryParams(queryUrl, searchParams)
  }

  public getImagesBySearchParams(searchParams) {
    const queryUrl = '/foto/search';
    return this.contentService.fetchContentWithQueryParams(queryUrl, searchParams)
  }

}
