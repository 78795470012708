import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from '@angular/common/http';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class UploadService {

  constructor(
    private http: HttpClient, 
    private _snackBar: MatSnackBar
  ) {}

  public upload(url, files: Set<File>) {
    // this will be the our resulting map
    const status = {};
    const onFilesComplete$ = new Subject<any>();

    files.forEach(file => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', url, formData, {
        reportProgress: true,
        withCredentials: true
      });

      // create a new progress-subject for every file
      const progress = new Subject<number>();

      // send the http-request and subscribe for progress-updates
      this.http.request(req).subscribe(
        event => {
        if (event.type === HttpEventType.UploadProgress) {

          // calculate the progress percentage
          const percentDone = Math.round(100 * event.loaded / event.total);

          // pass the percentage into the progress-stream
          progress.next(percentDone);
        } else if (event instanceof HttpResponse) {

          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();
          onFilesComplete$.next({
            file: file,
            response: event.body
          });
          this._snackBar.open(file.name + ' ble lastet opp', '', { duration: 2000});
        }
      },
    err => {
      if (err.status && err.status === 413) {
        this._snackBar.open(' Opplasting av ' + file.name + ' mislyktes  fordi bildefilen er for stor' , 'lukk');
      } else if (err.error && err.statusText) {
        this._snackBar.open(' Opplasting av ' + file.name + ' mislyktes: ' + err.statusText, 'lukk');
      } else {
        this._snackBar.open(' Opplasting av ' + file.name + ' mislyktes ' , 'lukk');
      }
      files.delete(file);
    });

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable()
      };
    });

    // return the map of progress.observables
    return [status, onFilesComplete$];
  }
}
