<div fxLayout="column">
    <mat-toolbar fxLayout="row" fxLayoutAlign="end center">
        <button mat-button color="primary" (click)="initNewUserForm()"><mat-icon>person_add</mat-icon> Ny bruker</button>
    </mat-toolbar>
    <mat-card *ngIf="newUserForm" style="margin:20px;">
        <form [formGroup]="newUserForm">
            <div fxLayout fxLayoutWrap fxLayoutGap="10px">
                <mat-form-field appearance="outline" fxFlex="calc(20% - 10px)">
                    <mat-label>Brukernavn</mat-label>
                    <input matInput placeholder="Brukernavn" formControlName="username">
                    <mat-error *ngIf="newUserForm.controls['username'].errors">
                        Påkrevet
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="calc(20% - 10px)">
                    <mat-label>Epost</mat-label>
                    <input matInput placeholder="Epost" formControlName="email">
                    <mat-error *ngIf="newUserForm.controls['email'].errors">
                        Ugyldig epost-adresse
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="calc(20% - 10px)">
                    <mat-label>Fornavn</mat-label>
                    <input matInput placeholder="Fornavn" formControlName="firstname">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="calc(20% - 10px)">
                    <mat-label>Etternavn</mat-label>
                    <input matInput placeholder="Etternavn" formControlName="lastname">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="20">
                    <mat-label>Passord</mat-label>
                    <input matInput placeholder="Passord" formControlName="password">
                    <mat-error *ngIf="newUserForm.controls['password'].errors">
                        Påkrevet
                    </mat-error>
                </mat-form-field>
        
            </div>
            <button mat-flat-button color="primary" (click)="saveNewUserData()" disabled="{{!newUserForm.valid}}">Lagre</button>
        </form>
    </mat-card>

    <div *ngIf="!usersForm" fxFlex fxLayout="column" fxLayoutAlign=" center">
        <mat-spinner [diameter]="40"></mat-spinner>
    </div>



    <!-- <div *ngIf="usersForm">
        <form [formGroup]="usersForm">
            <div formArrayName="users">
                <mat-accordion>
                    <ng-container *ngFor="let user of users.controls; let userIdx = index" [formGroupName]="userIdx">
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{user.get('firstname').value}} {{user.get('lastname').value}}
                            </mat-panel-title>
                            <mat-panel-description>
                                BrukerID: {{user.get('id').value}}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div fxFlexFill fxLayoutGap="10px grid">
                            <mat-form-field appearance="outline" fxFlex="25">
                                <mat-label>Brukernavn</mat-label>
                                <input matInput placeholder="Brukernavn" formControlName="username">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="25">
                                <mat-label>Epost</mat-label>
                                <input matInput placeholder="Epost" formControlName="email">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="25">
                                <mat-label>Fornavn</mat-label>
                                <input matInput placeholder="Fornavn" formControlName="firstname">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="25">
                                <mat-label>Etternavn</mat-label>
                                <input matInput placeholder="Etternavn" formControlName="lastname">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="25" *ngIf="user.get('editPassword').value">
                                <mat-label>Passord</mat-label>
                                <input matInput placeholder="Passord" formControlName="password">
                            </mat-form-field>
                            
                            <input type="checkbox" formControlName="editPassword">

                            <button (click)="saveUserData(userIdx)">Lagre</button>

                        </div>
                        <div fxLayout="row" fxLayoutAlign="end center">
                            <button mat-raised-button color="primary">Lagre</button>
                        </div>

                    </mat-expansion-panel>
                    </ng-container>
                </mat-accordion>
            </div>
        </form>
    </div> -->

    <!-- <br><br><br><br>
    <div>{{usersForm.value | json}}</div> -->

    <div *ngIf="usersForm">
        <form [formGroup]="usersForm">
            <div style="overflow-x:auto;">
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" formArrayName="users">

                    <ng-container matColumnDef="id">
                        <th mat-header-cell *matHeaderCellDef> ID </th>
                        <td mat-cell *matCellDef="let user">{{user.get('id').value}}</td>
                    </ng-container>

                    <ng-container matColumnDef="username">
                        <th mat-header-cell *matHeaderCellDef> Brukernavn </th>
                        <td mat-cell *matCellDef="let user; let userIdx = index;" [formGroupName]="userIdx">
                            <input type="text" formControlName="username">
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef> Epost </th>
                        <td mat-cell *matCellDef="let user; let userIdx = index;" [formGroupName]="userIdx">
                            <input [ngClass]="{'validation-error' : user.controls['email'].errors}" type="text" formControlName="email">
                            <div class="validation-error-text mat-small" *ngIf="user.controls['email'].errors">
                                Ugyldig epost-adresse
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="firstname">
                        <th mat-header-cell *matHeaderCellDef> Fornavn </th>
                        <td mat-cell *matCellDef="let user; let userIdx = index;" [formGroupName]="userIdx">
                            <input type="text" formControlName="firstname"></td>
                    </ng-container>

                    <ng-container matColumnDef="lastname">
                        <th mat-header-cell *matHeaderCellDef> Etternavn </th>
                        <td mat-cell *matCellDef="let user; let userIdx = index;" [formGroupName]="userIdx">
                            <input type="text" formControlName="lastname"></td>
                    </ng-container>

                    <ng-container matColumnDef="fotoCount">
                        <th mat-header-cell *matHeaderCellDef> Antall bilder </th>
                        <td mat-cell *matCellDef="let user; let userIdx = index;" [formGroupName]="userIdx">
                            <div style="text-align: center">
                                {{user.get('fotoCount').value}}
                            </div>
                    </ng-container>

                    <ng-container matColumnDef="delete">
                        <th mat-header-cell *matHeaderCellDef>Slett bruker</th>
                        <td mat-cell *matCellDef="let user; let userIdx = index;">
                            <!-- <mat-slide-toggle color="primary" formControlName="deleteUser"></mat-slide-toggle> -->
                            <!-- If user has zero images -->
                            <button *ngIf="isAdminUser" mat-icon-button color="warn" (click)="deleteUser(userIdx)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="password">
                        <th mat-header-cell *matHeaderCellDef> Rediger passord </th>
                        <td mat-cell *matCellDef="let user; let userIdx = index;" [formGroupName]="userIdx">
                            <mat-slide-toggle color="primary" formControlName="editPassword"></mat-slide-toggle>
                            <input class="new-password-input" *ngIf="user.get('editPassword').value" type="text" formControlName="password">
                        </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="editpassword">
                        <th mat-header-cell *matHeaderCellDef> Rediger passord </th>
                        <td mat-cell *matCellDef="let user; let userIdx = index;" [formGroupName]="userIdx">
                            <mat-slide-toggle color="primary" formControlName="editPassword"></mat-slide-toggle>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="password">
                        <th mat-header-cell *matHeaderCellDef> Nytt passord </th>
                        <td mat-cell *matCellDef="let user; let userIdx = index;" [formGroupName]="userIdx">
                            <input *ngIf="user.get('editPassword').value" type="text" formControlName="password">
                        </td>
                    </ng-container> -->

                    <ng-container matColumnDef="save">
                        <th mat-header-cell *matHeaderCellDef> </th>
                        <td mat-cell *matCellDef="let user; let userIdx = index;">
                            <button *ngIf="user.dirty" disabled="{{!user.valid}}" mat-button color="primary" (click)="saveUserData(userIdx)">Lagre</button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; let userIdx = index; columns: displayedColumns;">
                    </tr>
                </table>
            </div>
        </form>
    </div>
</div>