<input type="file" #file style="display: none" (change)="onFilesAdded()" multiple />

<div fxLayout="column">
    <mat-toolbar fxLayout="row" fxLayoutAlign="end center">
        <button mat-button color="primary" (click)="addFiles()"><mat-icon>file_upload</mat-icon> Last opp</button>
    </mat-toolbar>
    <mat-list>
        <h3 mat-subheader>Mine bilder</h3>
        <mat-list-item *ngFor="let file of files">
            <button mat-icon-button>
                <mat-icon>cancel</mat-icon>
            </button>
            <div mat-line>
                <mat-progress-bar *ngIf="progress && progress[file?.name]" mode="determinate" [value]="progress[file.name].progress | async"></mat-progress-bar>
            </div>
        </mat-list-item>
        <mat-list-item *ngFor="let image of images">
            <img matListAvatar [src]="image.image" alt="...">
            <a mat-line class="mat-h4" href="/single-photo/{{image.id}}" *ngIf="image.imageTitle">{{image.imageTitle}}</a>
            <p mat-line> {{image.updated | date:'short'}} </p>
            <button mat-icon-button (click)="deleteImage(image)">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-list-item>

        <!-- If uploading -->
        <!-- <mat-list-item>
            <div mat-line>
                <mat-progress-bar *ngIf="progress" mode="determinate" [value]="progress[file.name].progress | async"></mat-progress-bar>
            </div>
            <button mat-icon-button>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-list-item> -->
    </mat-list>
    <mat-paginator *ngIf="noOfImages" [length]="noOfImages" [pageIndex]="searchParams.offset" [pageSize]="searchParams.limit"
        [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)"></mat-paginator>
</div>
